import React, {useState, useEffect} from 'react';
import util from "../../util/util";
import {If, Paging, Perpagedd, Htmlvideo, SvgPlusIcon, Tinymce, SubmitTinymce, SetTinymceContent, RawHTML, VimeoVideo} from "../../util/controls";
import Questions from "./Questions";
let $=window.$;

let mRob={};

function pauseFormVideos(){
    /* $("#vplayer1").attr('src', 'url');
    video=$("#vplayer1")[0];
    video.load();
    video.play();
    video.pause(); */

    $(".frmvp").each(function(){
        let video=$(this)[0];
        video.pause();
    });
}

export default function Sessions(props) {
    const [d, setData]=useState({
        list:{result:[], page:{}}, 
        programs:[], 
        courses:[],
        modules:[],
        selPrgName:'', 
        selCourseName:'',
        selModuleName:'',
        selGroupName:'',
        sdata:{p:1, ps:25, program_id:0, course_id:0, module_id:0},
        gdata:{},
        data:{contents:[]},
        textData:{},
        vuploading:false,
    });
    const fn={};
    fn.render=()=>{setData({...d})}

    fn.handleChange=(e, dobj)=>{
        if(!dobj){
            dobj=d.data;
        }
        dobj[e.target.name]=e.target.value;
        setData({...d});
    }

    fn.sdata=(p, ps)=>{
        d.sdata.p=p?p:1;
        d.sdata.ps=ps?ps:d.sdata.ps;
        return d.sdata;
    }
    fn.lists=(e, p, ps)=>{
        if(e)e.preventDefault();
        util.ajx("master/session_groups", fn.sdata(p, ps)).then(res=>{
            if(res.code===200){
                setData({...d, list:res.result});
            }
        })
    }

    fn.selectProgram=(prg_id, title)=>{
        d.sdata.program_id=prg_id;
        d.selPrgName=title;
        d.sdata.course_id=0;
        d.selCourseName='';
        d.sdata.module_id=0;
        d.selModuleName='';
        fn.render();
    }
    fn.selectCourse=(course_id, title)=>{
        d.sdata.course_id=course_id;
        d.selCourseName=title;
        d.sdata.module_id=0;
        d.selModuleName='';
        fn.render();
    }
    fn.selectModule=(rob)=>{
        mRob=rob;
        d.sdata.module_id=rob.id;
        d.selModuleName=rob.title;
        fn.lists();
    }

    fn.openGroupForm=(dtl)=>{
        setData({...d, gdata:dtl?dtl:{module_id:d.sdata.module_id}});
        $("#gformModal").modal();
    }
    fn.saveGroup=()=>{
        let fd=new FormData($("#gfrm")[0]);
        util.ajx("master/save_session_group", fd).then(res=>{
            if(res.code===200){
                if(!d.data.id){
                    mRob['countsess']+=1;
                }
                fn.lists(0, d.gdata.id?d.sdata.p:1);
                $("#gformModal").modal('hide');
            }
        })
    }
    fn.deleteGroup=(id)=>{
        let callback=()=>{
            util.ajx("master/delete_session_group", {id:id}).then(res=>{
                if(res.code===200){
                    mRob['countsess']-=1;
                    fn.lists();
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);
    }

    fn.openForm=(dtl, gdtl)=>{
        setData({...d, data:dtl?{...dtl}:{id:'', group_id:gdtl.id, contents:[]}, selGroupName:gdtl.title});
        $("#sessionFormBx").show();
        $('[href="#stab1"]').click();
    }

    fn.save=()=>{
        let fd=new FormData($("#frm")[0]);
        util.ajx("master/save_session", fd).then(res=>{
            if(res.code===200){
                setData({...d, data:res.dtl});
            }
        })
    }

    fn.edit=(id)=>{
        util.ajx("master/session_dtl", {id:id}).then(res=>{
            if(res.code===200){
                fn.openForm(res.dtl, {title:res.dtl.session_group});
            }
        })
    }

    fn.deleterec=(id)=>{
        let callback=()=>{
            util.ajx("master/delete_session", {id:id}).then(res=>{
                if(res.code===200){
                    fn.lists();
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);
    }
    
    fn.openVideoForm=()=>{
        setData({...d, vuploading:false});
        $("#upVideoModal input[type='file']").val('');
        window.showModal($("#upVideoModal"), true);
    }
    fn.uploadVideo=(e)=>{
        let fname=e.target.value;
        let ext=window.getExt(fname);
        let exts=['mp4', 'webp', 'mkv', 'avi', 'mov'];
        if(exts.indexOf(ext)===-1){
            window.showAlertMsg("Invalid video file!", "E");
            $("#upVideoModal input[type='file']").val('');
            return;
        }

        setData({...d, vuploading:true});
        let fd=new FormData($("#up_video_frm")[0]);
        util.ajx("master/upload_session_video", fd, 0,1).then(res=>{
            if(res.code===200){
                $("#upVideoModal").modal('hide');
                setData({...d, data:res.dtl});
            }else{
                setData({...d, vuploading:false});
                $("#upVideoModal input[type='file']").val('');
            }
        })
    }

    fn.openVimeoForm=(dtl)=>{
        setData({...d, textData:dtl?{...dtl}:{}});
        window.showModal($("#upVimeoModal"), true);
    }
    fn.saveVimeoUrl=()=>{
        let fd=new FormData($("#up_vimeo_frm")[0]);
        util.ajx("master/save_session_vimeo_url", fd).then(res=>{
            if(res.code===200){
                d.data=res.dtl;
                fn.render();
                $("#upVimeoModal").modal('hide');
            }
        })
    }

    fn.openImageForm=()=>{
        setData({...d, vuploading:false});
        $("#upImageModal input[type='file']").val('');
        window.showModal($("#upImageModal"), true);
    }
    fn.uploadImage=(e)=>{
        let fname=e.target.value;
        let ext=window.getExt(fname);
        let exts=['jpg', 'jpeg', 'png'];
        if(exts.indexOf(ext)===-1){
            window.showAlertMsg("Invalid image file!", "E");
            $("#upImageModal input[type='file']").val('');
            return;
        }

        setData({...d, vuploading:true});
        let fd=new FormData($("#up_image_frm")[0]);
        util.ajx("master/upload_session_image", fd, 0,1).then(res=>{
            if(res.code===200){
                $("#upImageModal").modal('hide');
                setData({...d, data:res.dtl});
            }else{
                setData({...d, vuploading:false});
                $("#upImageModal input[type='file']").val('');
            }
        })
    }

    fn.openTextForm=(dtl)=>{
        setData({...d, textData:dtl?{...dtl}:{}});
        window.showModal($("#upTextModal"), true);
    }
    fn.saveText=()=>{
        SubmitTinymce("ste1");
        let fd=new FormData($("#up_text_frm")[0]);
        util.ajx("master/save_session_text", fd).then(res=>{
            if(res.code===200){
                d.data=res.dtl;
                fn.clearTextForm();
                $("#upTextModal").modal('hide');
            }
        })
    }
    fn.clearTextForm=()=>{
        SetTinymceContent("ste1", "");
        setData({...d, textData:{}});
    }
    fn.deleteSessContent=(id)=>{
        let callback=()=>{
            util.ajx("master/delete_session_content", {id:id}).then(res=>{
                if(res.code===200){
                    setData({...d, data:res.dtl});
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);
    }

    fn.updateDispOrder=()=>{
        let fd=new FormData($("#dispOdrFrm")[0]);
        util.ajx("master/save_session_content_disp_odr", fd).then(res=>{
            if(res.code===200){
                setData({...d, data:res.dtl});
            }
        })
    }

    fn.publish=(id)=>{
        let callback=()=>{
            let fd=new FormData($("#dispOdrFrm")[0]);
            util.ajx("master/publish_session", fd).then(res=>{
                if(res.code===200){
                    setData({...d, data:res.dtl});
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to publish?", callback);
    }

    fn.saveDraft=(id)=>{
        let fd=new FormData($("#dispOdrFrm")[0]);
        util.ajx("master/save_draft_session", fd).then(res=>{
            if(res.code===200){
                setData({...d, data:res.dtl});
            }
        })
    }
    
    fn.onFormClose=()=>{
        pauseFormVideos();
        d.data={contents:[]};
        fn.lists(0);
    }

    fn.init=()=>{
        util.ajx("master/init_sessions", d.sdata).then(res=>{
            if(res.code === 200){
                res.modules.forEach((v)=>{
                    if(d.sdata.module_id===v.id){
                        d.selModuleName=v.title;
                        d.sdata.course_id=v.course_id;
                        d.sdata.program_id=v.program_id;
                        mRob=v;
                    }
                })
                res.courses.forEach((v)=>{
                    if(d.sdata.course_id===v.id){
                        d.selCourseName=v.title;
                    }
                })
                res.programs.forEach((v)=>{
                    if(d.sdata.program_id===v.id){
                        d.selPrgName=v.title;
                    }
                })

                setData({...d, list:res.result, programs:res.programs, courses:res.courses, modules:res.modules});
            }
        });
    }

    useEffect(()=>{
        let module_id=props.computedMatch.params.module_id || '';
        d.sdata={...d.sdata, module_id:module_id}
        fn.init();
    }, []);

    return List(d, fn);
}

function List(d, fn){
    return(<>
            <div className="page-head sm">
                <div className="container-fluid d-flex">
                    <h5 className="mt-auto mb-auto pr15">Sessions</h5>
                    <div className="mt-auto mb-auto font-xs text-secondary">
                        {d.selPrgName && <span className="bsep">{d.selPrgName}</span>}
                        {d.selCourseName && <span className="bsep"> {d.selCourseName}</span>}
                        <span className="bread-act"> {d.selModuleName}</span>
                    </div>
                    <div className="ml-auto mt-auto mb-auto">
                        <button className="btn btn-sm btn-outline-secondary bdr-radius-0" onClick={()=>window.history.back()}><i className="fa fa-chevron-circle-left"></i> Back</button>
                    </div>
                </div>
            </div>

            <div className="main-content mc-full-height">
                <div className="container-fluid h-100">
                    <div className="d-flex h-100">
                        <div className="plist-card w200">
                            <div className="plist-card-head">Programs</div>
                            <div className="plist-card-body">
                                <ul className="items-ul">
                                    {d.programs.map(r=>(
                                    <li key={r.id} className={r.id===d.sdata.program_id?'active':''} onClick={()=>fn.selectProgram(r.id, r.title)}>
                                        {r.title}
                                        <div className="pt3 note-text">{r['countc']+' Courses'}</div>
                                    </li>))}
                                </ul>
                            </div>
                        </div>
                        <div className="plist-card w200">
                            <div className="plist-card-head">Courses</div>
                            <div className="plist-card-body">
                                <ul className="items-ul">
                                    {d.courses.map(r=>(
                                        <If key={r.id} cond={r.program_id===d.sdata.program_id}>
                                            <li className={r.id===d.sdata.course_id?'active':''} onClick={()=>fn.selectCourse(r.id, r.title)}>
                                                {r.title}
                                                <div className="pt3 note-text">{r['countm']} Modules</div>
                                            </li>
                                        </If>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="plist-card w260">
                            <div className="plist-card-head">Modules</div>
                            <div className="plist-card-body">
                                <ul className="items-ul">
                                    {d.modules.map(r=>(
                                        <If key={r.id} cond={r.course_id===d.sdata.course_id}>
                                            <li className={r.id===d.sdata.module_id?'active':''} onClick={()=>fn.selectModule(r)}>
                                                {r.title}
                                                <div className="pt3 note-text">{r['countsess']+' Sessions'}</div>
                                            </li>
                                        </If>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="plist-card w100-670">
                            <div className="plist-card-head">Sessions <span className="text-secondary">/ {d.selModuleName}</span></div>
                            <div className="plist-card-body">
                                <If cond={!d.sdata.module_id}><div className="p30 text-center text-secondary">Select program / course / module to see sessions</div></If>

                                <If cond={d.sdata.module_id}>
                                    <div className="p8">
                                        <If cond={d.list.result.length}>
                                            <div className="d-flex">
                                                <div className="text-secondary bold600 mb5">
                                                    Showing {d.list.page.start+1} - {d.list.page.start+d.list.page.total} of {d.list.page.total_records} records.
                                                </div>
                                            </div>
                                        </If>

                                        <div className="d-flex tbl-search-head">
                                            <div className="form-inline">
                                                <form id="sfrm" onSubmit={fn.lists} autoComplete="off" spellCheck="false">
                                                    <div className="input-group">
                                                        <input type="search" className="form-control" name="k" placeholder="Search" value={d.sdata.k || ''} onChange={e=>fn.handleChange(e, d.sdata)} />
                                                        <div className="input-group-append">
                                                            <button className="btn btn-light-blue" type="submit"><i className="fa fa-search"></i></button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>

                                            <div className="ml-auto mt-auto mb-auto">
                                                <button className="btn btn-primary" onClick={()=>fn.openGroupForm()}><i className="fa fa-plus"></i> Add</button>
                                            </div>
                                        </div>

                                        <If cond={d.list.result.length}>
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-sm table-striped table-hover1 m-0">
                                                    <thead className="thead-light text-uppercase table-text-vmid font-sm">
                                                        <tr>
                                                            <th className="w20">SN</th>
                                                            <th>Session Name</th>
                                                            <th className="w80">Status</th>
                                                            <th className="w70"></th>
                                                        </tr>
                                                    </thead>

                                                    <tbody className="table-text-vtop">
                                                        {d.list.result.map((v, i)=>(
                                                            <tr key={i}>
                                                                <td>{d.list.page.start+i+1}.</td>
                                                                
                                                                <td>
                                                                    <div className="bold600 pb5">{v.title}</div>
                                                                    <If cond={v.sessions.length}>
                                                                        <div className="table-responsive mb5">
                                                                            <table className="table table-bordered table-sm table-hover1 m-0">
                                                                                <thead className="thead-light text-uppercase table-text-vmid font-sm">
                                                                                    <tr>
                                                                                        <th>Segment</th>
                                                                                        <th className="w80">Status</th>
                                                                                        <th className="w70"></th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody className="table-text-vmid">
                                                                                    {v.sessions.map((s, i)=>(
                                                                                        <tr key={i}>
                                                                                            <td>{s.title}</td>
                                                                                            <td>
                                                                                                <If cond={s.status}>
                                                                                                    <div className="badge badge-success">Published</div>
                                                                                                </If>
                                                                                                <If cond={!s.status}>
                                                                                                    <div className="badge badge-secondary">Draft</div>
                                                                                                </If>
                                                                                            </td>
                                                                                            <td className="text-center">
                                                                                                <div className="btn-group">
                                                                                                    <button type="button" className="btn btn-sm btn-light-blue gr" onClick={()=>fn.edit(s.id)}><i className="fa fa-edit"></i></button>
                                                                                                    <button type="button" className="btn btn-sm btn-danger gr" onClick={()=>fn.deleterec(s.id)}><i className="fa fa-trash-o"></i></button>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </If>
                                                                    <button className="btn btn-sm btn-dark" onClick={()=>fn.openForm(0, v)}><i className="fa fa-plus"></i> Add Segment</button>
                                                                </td>
                                                                
                                                                <td>
                                                                    <If cond={v.status}>
                                                                        <div className="badge badge-success">Active</div>
                                                                    </If>
                                                                    <If cond={!v.status}>
                                                                        <div className="badge badge-secondary">Inactive</div>
                                                                    </If>
                                                                </td>
                                                                <td className="text-center">
                                                                    <div className="btn-group">
                                                                        <button type="button" className="btn btn-sm btn-light-blue gr" onClick={()=>fn.openGroupForm(v)}><i className="fa fa-edit"></i></button>
                                                                        <button type="button" className="btn btn-sm btn-danger gr" onClick={()=>fn.deleteGroup(v.id)}><i className="fa fa-trash-o"></i></button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="d-flex tbl-foot-bx">
                                                <div className="mr5">
                                                    <Paging fn={fn.lists} pageob={d.list.page} />
                                                </div>
                                                <div>
                                                    <Perpagedd fn={fn.lists} />
                                                </div>
                                            </div>
                                        </If>
                                        <If cond={!d.list.result.length}>
                                            <div className="no-rec">No record found</div>
                                        </If>
                                    </div>
                                </If>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {GroupForm(d, fn)}
            {Form(d, fn)}
            {VideoForm(d, fn)}
            {VimeoUrlForm(d, fn)}
            {ImageForm(d, fn)}
            {TextForm(d, fn)}
        </>
    )
}

function GroupForm(d, fn){
    return(
        <div className="modal modal-scroll" id="gformModal" data-backdrop="static">
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                    <div className="modal-title uc">{d.gdata.id?'Edit':'Add'} Session</div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb10 form-control bg-light">
                            <span className="bsep">{d.selPrgName}</span>
                            <span className="bsep"> {d.selCourseName}</span>
                            <span className="bread-act"> {d.selModuleName}</span>
                        </div>
                        <form id="gfrm" autoComplete="off" spellCheck="false">
                            <input type="hidden" name="id" defaultValue={d.gdata.id} />
                            <input type="hidden" name="module_id" defaultValue={d.gdata.module_id} />
                            <div className="row mingap">
                                <div className="form-group col-md-12">
                                    <label className="req">Session Name</label>
                                    <input type="text" className="form-control" name="title" value={d.gdata.title || ''} onChange={e=>fn.handleChange(e, d.gdata)} />
                                </div>
                                <div className="form-group col-md-12"> 
                                    <label className="req">Status</label>
                                    <select className="form-control" name="status" value={d.gdata.status+'' || ''} onChange={e=>fn.handleChange(e, d.gdata)}>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={fn.saveGroup}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Form(d, fn){
    return (
        <div id="sessionFormBx" className="big-over-bx">
            <div className="big-over-bx-head d-flex">
                <div className="bg-dark d-flex big-over-bx-close" onClick={fn.onFormClose}>
                    <div className="mt-auto mb-auto"> <i className="fa fa-times"></i> </div>
                </div>

                <div className="mt-auto mb-auto uc pl15 pr15">{d.data.id?'Edit':'Add'} Segment </div>
                <div className="ml-auto mt-auto mb-auto">
                    {/* <button type="button" className="btn border-secondary mr10" onClick={fn.openVideoForm} disabled={!d.data.id}><SvgPlusIcon /> Add Video</button>
                    <button type="button" className="btn border-secondary mr10" onClick={fn.openImageForm} disabled={!d.data.id}><SvgPlusIcon /> Add Image</button>
                    <button type="button" className="btn border-secondary mr10" onClick={fn.openTextForm} disabled={!d.data.id}><SvgPlusIcon /> Add Text</button> */}

                    <button type="button" className="btn btn-secondary mr10" onClick={()=>fn.saveDraft(d.data.id)} disabled={!d.data.id}>Save Draft</button>
                    <button type="button" className="btn btn-primary" onClick={()=>fn.publish(d.data.id)} disabled={!d.data.id}>Publish</button>
                </div>
            </div>

            <div className="big-over-bx-body">
                <div className="w900 m-auto">
                    <div className="form-group">
                        <If cond={d.data.id && d.data.status}><div className="text-success text-center bold mb10"> <i className="fa fa-check-circle"></i> Published @ <span>{d.data.published_on}</span></div></If>
                        <div className="mb10 form-control bg-light">
                            <span className="bsep">{d.selPrgName}</span>
                            <span className="bsep"> {d.selCourseName}</span>
                            <span className="bsep"> {d.selModuleName}</span>
                            <span className="bread-act"> {d.selGroupName}</span>
                        </div>

                        <form id="frm" autoComplete="off" spellCheck="false">
                            <input type="hidden" name="id" defaultValue={d.data.id} />
                            <input type="hidden" name="group_id" defaultValue={d.data.group_id} />

                            <div className="input-group">
                                <input type="text" className="form-control font-lg" name="title" value={d.data.title || ''} onChange={fn.handleChange} placeholder="Enter segment name" />
                                <div className="input-group-append">
                                    <button type="button" className="btn btn-dark btn-block height-inherit" onClick={fn.save}>{d.data.id?'Update Segment Name':'Create Segment'}</button>
                                </div>
                            </div>
                        </form>
                        <If cond={!d.data.id}><div className="p30 text-center text-secondary">After creating segment you can add further details</div></If>
                    </div>

                    <div className={!d.data.id?'d-none':''}>
                        <div className="tabbable-custom">
                            <ul className="nav nav-tabs uc">
                                <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#stab1">Contents</a></li>
                                <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#stab2">Questions</a></li>
                            </ul>
                            <div className="tab-content">
                                <div id="stab1" className="tab-pane fade show active">
                                    <div className="">
                                        <form id="dispOdrFrm" autoComplete="off" spellCheck="false">
                                            <input type="hidden" name="session_id" defaultValue={d.data.id} />
                                            {d.data.contents.map((r, i)=>(
                                                <div key={i} className="form-group p8 border border-light bg-light shadow">
                                                    <div className="sess-data-bx">
                                                        <div className="disp-odr" title="Display Order">
                                                            <input type="text" className="form-control shadow-sm" name={'disp_odr['+r.id+']'} value={r.disp_odr} onChange={e=>{r.disp_odr=e.target.value; fn.render();}} />
                                                        </div>
                                                        <div className="controls">
                                                            <div className="btn-group">
                                                                <If cond={r.type==='TEXT'}>
                                                                    <button type="button" className="btn btn-light-blue gr" onClick={()=>fn.openTextForm(r)}><i className="fa fa-edit"></i> Edit</button>
                                                                </If>
                                                                <If cond={r.vimeo_id}>
                                                                    <button type="button" className="btn btn-light-blue gr" onClick={()=>fn.openVimeoForm(r)}><i className="fa fa-edit"></i> Edit</button>
                                                                </If>
                                                                <button type="button" className="btn btn-danger gr" onClick={()=>fn.deleteSessContent(r.id)}><i className="fa fa-trash-o"></i> Delete</button>
                                                            </div>
                                                        </div>
                                                        <div className="sess-content">
                                                            <If cond={r.type==='VIDEO'}>
                                                                <If cond={!r.vimeo_id}>
                                                                    <div style={{fontSize:0}}>
                                                                        <Htmlvideo className="vplayer1 frmvp" src={r.video_url} />
                                                                    </div>
                                                                </If>
                                                                <If cond={r.vimeo_id}>
                                                                    <VimeoVideo videoid={r.vimeo_id} height="300px" />
                                                                </If>
                                                            </If>
                                                            <If cond={r.type==='IMAGE'}>
                                                                <img className="mw-100" src={r.image_url} alt="" />
                                                            </If>
                                                            <If cond={r.type==='TEXT'}>
                                                                <div className="pt5 pl5 pr5"><RawHTML html={r.content} /></div>
                                                            </If>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </form>
                                    </div>

                                    <If cond={d.data.id}>
                                        <div className="text-center pt15 pb15">
                                            <button type="button" className="btn border-secondary mr10" onClick={fn.openVideoForm}><SvgPlusIcon /> Upload Video</button>
                                            <button type="button" className="btn border-secondary mr10" onClick={fn.openVimeoForm}><SvgPlusIcon /> Add Vimeo Url</button>
                                            <button type="button" className="btn border-secondary mr10" onClick={fn.openImageForm}><SvgPlusIcon /> Add Image</button>
                                            <button type="button" className="btn border-secondary mr10" onClick={fn.openTextForm}><SvgPlusIcon /> Add Text</button>
                                            {/* <If cond={d.data.contents.length}>
                                                <button type="button" className="btn btn-info" onClick={fn.updateDispOrder}>Save Display Order</button>
                                            </If> */}
                                        </div>
                                    </If>
                                </div>

                                <div id="stab2" className="tab-pane fade show">
                                    <If cond={d.data.id}>
                                        <Questions module_id={d.data.module_id} session_id={d.data.id} type="" />
                                    </If>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function VideoForm(d, fn){
    return (
        <div className="modal" id="upVideoModal" data-backdrop="static">
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">Upload Video</div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <form id="up_video_frm" autoComplete="off" spellCheck="false">
                            <input type="hidden" name="session_id" defaultValue={d.data.id} />

                            <div className="position-relative" style={{border:'1px dashed #ffc107'}}>
                                <input type="file" name="video" className="video-up-input" onChange={(e)=>fn.uploadVideo(e)} />
                                <div className="d-flex m-auto video-up-bx">
                                    <div className="m-auto">
                                        <If cond={!d.vuploading}>
                                            <div>Click or Drag Video File Here</div>
                                        </If>
                                        <If cond={d.vuploading}>
                                            <div>
                                                <div className="pb10 text-center">Uploading</div>
                                                <img src="assets/img/loading.gif" alt="" />
                                            </div>
                                        </If>
                                    </div>
                                </div>
                            </div>
                            <div className="pt5 text-secondary">Only .mp4, .webp, .mkv, .avi and .mov are allowed</div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function VimeoUrlForm(d, fn){
    return (
        <div className="modal" id="upVimeoModal" data-backdrop="static">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">{d.textData.id?'Edit':'Add'} Vimeo Url</div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <form id="up_vimeo_frm" autoComplete="off" spellCheck="false">
                            <input type="hidden" name="id" defaultValue={d.textData.id} />
                            <input type="hidden" name="session_id" defaultValue={d.data.id} />

                            <div>
                                <label className="req">Vimeo Video Url</label>
                                <input type="text" className="form-control" name="vimeo_video_url" value={d.textData.vimeo_video_url || ''} onChange={e=>{
                                    d.textData.vimeo_video_url=e.target.value;
                                    fn.render();
                                }} />
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={fn.saveVimeoUrl}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ImageForm(d, fn){
    return (
        <div className="modal" id="upImageModal" data-backdrop="static">
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">Upload Image</div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <form id="up_image_frm" autoComplete="off" spellCheck="false">
                            <input type="hidden" name="session_id" defaultValue={d.data.id} />

                            <div className="position-relative" style={{border:'1px dashed #ffc107'}}>
                                <input type="file" name="video" className="video-up-input" onChange={(e)=>fn.uploadImage(e)} />
                                <div className="d-flex m-auto video-up-bx">
                                    <div className="m-auto">
                                        <If cond={!d.vuploading}>
                                            <div>Click or Drag Image File Here</div>
                                        </If>
                                        <If cond={d.vuploading}>
                                            <div>
                                                <div className="pb10 text-center">Uploading</div>
                                                <img src="assets/img/loading.gif" alt="" />
                                            </div>
                                        </If>
                                    </div>
                                </div>
                            </div>
                            <div className="pt5 text-secondary">Only .png, .jpg and .jpeg are allowed (Max Size: 1MB)</div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function TextForm(d, fn){
    return (
        <div className="modal" id="upTextModal" data-backdrop="static">
            <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">{d.textData.id?'Edit':'Add'} Text</div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={fn.clearTextForm}><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <form id="up_text_frm" autoComplete="off" spellCheck="false">
                            <input type="hidden" name="id" defaultValue={d.textData.id} />
                            <input type="hidden" name="session_id" defaultValue={d.data.id} />

                            <div>
                                <Tinymce id="ste1" height="500" name="content" data={d.textData.content} />
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={fn.clearTextForm}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={fn.saveText}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}