import React, {useState, useEffect} from 'react';
import util from "../../util/util";
import {If, Paging, Perpagedd} from "../../util/controls";
let $=window.$;

export default function Payments() {
    const fn={};
    const [d, setData]=useState({
        list:{result:[], page:{}},
        sdata:{p:1, ps:25},
        data:{},
        txResp:{},
    });

    fn.render=()=>{
        setData({...d});
    }

    fn.handleChange=(e)=>{
        d.data[e.target.name]=e.target.value;
        fn.render();
    }

    fn.sdata=(p, ps)=>{
        d.sdata.p=p?p:1;
        d.sdata.ps=ps?ps:d.sdata.ps;
        return $("#sfrm").serialize()+'&p='+d.sdata.p+'&ps='+d.sdata.ps;
    }
    fn.lists=(e, p, ps)=>{
        if(e)e.preventDefault();
        util.ajx("student/payments", fn.sdata(p, ps)).then(res=>{
            if(res.code===200){
                d.list=res.result;
                fn.render();
            }
        })
    }

    fn.checkStatus=(id, i, only_resp_view)=>()=>{
        util.ajx("student/check_cashfree_payment_status", {id, only_resp_view}).then(res=>{
            if(res.code===200){
                let r=res.data;
                if(r){
                    d.list.result[i].status=r.status;
                    d.list.result[i].txn_id=r.txn_id;
                    d.list.result[i].txn_date=r.txn_date;
                    d.list.result[i].payment_mode=r.payment_mode;
                }

                d.txResp=util.obj(res.txResp);
                fn.render();
                window.showModal($("#txnDtlModal"), true);
            }
        })
    }

    fn.init=()=>{
        util.setDatepicker();
        fn.lists();
    }
    useEffect(()=>{fn.init();}, []);

    return View(d, fn);
}

function View(d, fn){
    return(
        <>
            <div className="page-head">
                <div className="container-fluid d-flex">
                    <h5 className="mt-auto mb-auto">Payments</h5>
                </div>
            </div>

            <div className="main-content">
                <div className="container-fluid">
                    <If cond={d.list.result.length}>
                        <div className="d-flex">
                            <div className="text-secondary bold600 mb5">
                                Showing {d.list.page.start+1} - {d.list.page.start+d.list.page.total} of {d.list.page.total_records} records.
                            </div>
                        </div>
                    </If>

                    <div className="d-flex tbl-search-head">
                        <div className="form-inline">
                            <form id="sfrm" onSubmit={fn.lists} autoComplete="off" spellCheck="false">
                                <input type="search" className="form-control w350" name="k" placeholder="Search name/email/program/OrderID/TXN ID" />
                                <select className="form-control ml5" name="status">
                                    <option value="">Status (SUCCESS + FAIL)</option>
                                    <option>SUCCESS</option>
                                    <option>FAIL</option>
                                    <option>INITIATED</option>
                                    <option>ALL</option>
                                </select>
                                <input type="text" className="form-control w120 ml5 cal hasCal" name="from_date" placeholder="From Date" />
                                <input type="text" className="form-control w120 ml5 cal hasCal" name="to_date" placeholder="To Date" />
                                <button className="btn btn-light-blue ml5" type="submit"><i className="fa fa-search"></i> Search</button>
                            </form>
                        </div>

                        <div className="ml-auto mt-auto mb-auto">
                        </div>
                    </div>

                    <div>
                        <If cond={d.list.result.length}>
                            <div className="table-responsive">
                                <table className="table table-bordered table-sm font-sm table-striped table-hover m-0">
                                    <thead className="thead-light text-uppercase table-text-vmid font-xs">
                                        <tr>
                                            <th className="w20">SN</th>
                                            <th>Student Name</th>
                                            <th className="w200">Program</th>
                                            <th className="w80">Coupon</th>
                                            <th className="w80 text-center">Discount</th>
                                            <th className="w80 text-center">Paid Amt</th>
                                            <th className="w60">Status</th>
                                            <th className="w100">Mode</th>
                                            <th className="w100">Order ID</th>
                                            <th className="w80">Txn ID</th>
                                            <th className="w100">Txn Date</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-text-top">
                                        {d.list.result.map((v, i)=>(
                                            <tr key={i}>
                                                <td>{d.list.page.start+i+1}.</td>
                                                <td>
                                                    {v.name}
                                                    <div className="note-text">{v.email}</div>
                                                </td>
                                                <td>{v.program}</td>
                                                <td className="nowrap">{v.coupon}</td>
                                                <td className="text-right nowrap">₹{v.coupon_amt}</td>
                                                <td className="text-right nowrap bold600">₹{v.amount}</td>
                                                <td>
                                                    <If cond={v.status==='SUCCESS'}>
                                                        <div className="badge badge-success">SUCCESS</div>
                                                    </If>
                                                    <If cond={v.status==='FAIL'}>
                                                        <div className="badge badge-danger">FAIL</div>
                                                    </If>
                                                    <If cond={v.status==='INITIATED'}>
                                                        <div className="badge badge-secondary">INITIATED</div>
                                                    </If>
                                                    <div className="pt2">
                                                        <div className="link font-xss" onClick={fn.checkStatus(v.id, i, 0)}>Check Api</div>
                                                    </div>    
                                                </td>
                                                <td>{v.payment_mode}</td>
                                                <td><div className="link font-xss" onClick={fn.checkStatus(v.id, i, 1)}>{v.order_no}</div></td>
                                                <td>{v.txn_id}</td>
                                                <td className="nowrap">{v.txn_date}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex tbl-foot-bx">
                                <div className="mr5">
                                    <Paging fn={fn.lists} pageob={d.list.page} />
                                </div>
                                <div>
                                    <Perpagedd fn={fn.lists} />
                                </div>
                            </div>
                        </If>
                        <If cond={!d.list.result.length}>
                            <div className="no-rec">No record found</div>
                        </If>
                    </div>
                </div>
            </div>

            <div className="modal modal-scroll" id="txnDtlModal" data-backdrop="static">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title uc">Transaction Api Response</div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="table-responsive">
                                <table className="table table-bordered table-sm m-0">
                                    <tbody className="table-text-vmid">
                                        {Object.keys(d.txResp).map((k, i)=>(
                                            <tr key={i}>
                                                <td className="w150">{k}</td>
                                                <If cond={typeof d.txResp[k] !== "object"}>
                                                    <td className="bold600">{d.txResp[k]}</td>
                                                </If>
                                                <If cond={typeof d.txResp[k] === "object"}>
                                                    <td className="">
                                                        {Object.keys(d.txResp[k]).map((k1, j)=>(
                                                            <div key={j}>
                                                                <div className="inblock w100">{k1}</div>:&nbsp;
                                                                <span>
                                                                    <If cond={typeof d.txResp[k][k1] === "object"}>
                                                                        {JSON.stringify(d.txResp[k][k1])}
                                                                    </If>
                                                                    <If cond={typeof d.txResp[k][k1] !== "object"}>
                                                                        {d.txResp[k][k1]}
                                                                    </If>
                                                                </span>
                                                            </div>
                                                        ))}
                                                    </td>
                                                </If>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}