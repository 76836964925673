import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import util from "../../util/util";
import {If, Paging, Perpagedd, Tinymce, SubmitTinymce, SetTinymceContent, Htmlvideo, VimeoVideo} from "../../util/controls";
let $=window.$;

export default function Program() {
    const fn={};
    const [d, setData]=useState({
        list:{result:[], page:{}},
        sdata:{p:1, ps:25},
        data:{}
    });

    fn.render=()=>{
        setData({...d});
    }

    fn.handleChange=(e)=>{
        d.data[e.target.name]=e.target.value;
        fn.render();
    }

    fn.openForm=(dtl)=>{
        SetTinymceContent("te1", "");
        d.data=dtl?{...dtl}:{};
        fn.render();
        window.showModal($("#formModal"), true);
        $('#formModal input[type="file"]').val('');
    }

    fn.pauseVideo=()=>{
        let video=$(".vplayer1")[0];
        if(video && typeof video !== "undefined"){
            video.pause();
        }
    }

    const sdata=(p, ps)=>{
        d.sdata.p=p?p:1;
        d.sdata.ps=ps?ps:d.sdata.ps;
        return $("#sfrm").serialize()+'&p='+d.sdata.p+'&ps='+d.sdata.ps;
    }
    fn.lists=(e, p, ps)=>{
        if(e)e.preventDefault();
        util.ajx("master/programs", sdata(p, ps)).then(res=>{
            if(res.code===200){
                d.list=res.result;
                fn.render();
            }
        })
    }

    fn.save=()=>{
        SubmitTinymce("te1");
        let fd=new FormData($("#frm")[0]);
        util.ajx("master/save_program", fd).then(res=>{
            if(res.code===200){
                fn.pauseVideo();
                window.hideModal($("#formModal"));
                fn.lists(0, d.data.id?d.sdata.p:1);
            }
        })
    }

    fn.deleterec=(id)=>{
        let callback=()=>{
            util.ajx("master/delete_program", {id:id}).then(res=>{
                if(res.code===200){
                    fn.lists();
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);
    }

    const init=()=>{
        fn.lists();
    }
    useEffect(()=>{init();}, []);

    return View(d, fn);
}
function View(d, fn){
    return(
        <>
            <div className="page-head">
                <div className="container-fluid d-flex">
                    <h5 className="mt-auto mb-auto">Manage Programs</h5>
                    <div className="ml-auto mt-auto mb-auto">
                        <button className="btn btn-outline-primary" onClick={()=>fn.openForm()}><i className="fa fa-plus"></i> Add</button>
                    </div>
                </div>
            </div>

            <div className="main-content">
                <div className="container-fluid">
                    <If cond={d.list.result.length}>
                        <div className="d-flex">
                            <div className="text-secondary bold600 mb5">
                                Showing {d.list.page.start+1} - {d.list.page.start+d.list.page.total} of {d.list.page.total_records} records.
                            </div>
                        </div>
                    </If>

                    <div className="d-flex tbl-search-head">
                        <div className="form-inline">
                            <form id="sfrm" onSubmit={fn.lists} autoComplete="off" spellCheck="false">
                                <div className="input-group">
                                    <input type="search" className="form-control" name="k" placeholder="Search" />
                                    <div className="input-group-append">
                                        <button className="btn btn-light-blue" type="submit"><i className="fa fa-search"></i></button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="ml-auto mt-auto mb-auto">
                        </div>
                    </div>

                    <div>
                        <If cond={d.list.result.length}>
                            <div className="table-responsive">
                                <table className="table table-bordered table-sm table-striped table-hover m-0">
                                    <thead className="thead-light text-uppercase table-text-vmid font-sm">
                                        <tr>
                                            <th className="w20">SN</th>
                                            <th className="w100">Image</th>
                                            <th>Program Name</th>
                                            <th className="w100">Price</th>
                                            <th className="w100">Expiry Days</th>
                                            <th className="w80">Status</th>
                                            <th className="w80"></th>
                                            <th className="w80"></th>
                                            <th className="w80"></th>
                                            <th className="w70"></th>
                                        </tr>
                                    </thead>

                                    <tbody className="table-text-top">
                                        {d.list.result.map((v, i)=>(
                                            <tr key={i}>
                                                <td>{d.list.page.start+i+1}.</td>
                                                <td><img src={v.image_url} className="mw-100" alt="image"></img></td>
                                                <td>
                                                    <div>
                                                        <span className="text-uppercase">{v.title}</span> {v.is_new===1 && <span className="badge badge-success rounded-0 ml5">New</span>}
                                                    </div>
                                                    <div className="text-secondary">{v.tag}</div>
                                                    <div className="note-text">Display Order: <strong>{v.disp_odr}</strong></div>
                                                </td>
                                                
                                                <td>{v.price}</td>
                                                <td className="nowrap">{v.expiry_days} Days</td>
                                                <td>
                                                    <If cond={v.status==='1'}>
                                                        <div className="badge badge-success">Active</div>
                                                    </If>
                                                    <If cond={v.status==='0'}>
                                                        <div className="badge badge-danger">Inactive</div>
                                                    </If>
                                                </td>
                                                <td className="text-center">
                                                    <Link to={'courses/'+v.id} className="btn btn-sm btn-primary nowrap">Courses <span className="badge badge-warning">{v.no_of_courses}</span></Link>
                                                </td>
                                                <td className="text-center">
                                                    <Link to={'assignments/'+v.id} className="btn btn-sm btn-dark nowrap">Assignments <span className="badge badge-warning">{v.no_of_assignments}</span></Link>
                                                </td>
                                                <td className="text-center">
                                                    <Link to={'tests/'+v.id} className="btn btn-sm btn-warning nowrap">Mock Tests <span className="badge badge-light">{v.no_of_tests}</span></Link>
                                                </td>
                                                <td className="text-center">
                                                    <div className="btn-group">
                                                        <button type="button" className="btn btn-sm btn-light-blue gr" onClick={()=>fn.openForm(v)}><i className="fa fa-edit"></i></button>
                                                        <button type="button" className="btn btn-sm btn-danger gr" onClick={()=>fn.deleterec(v.id)}><i className="fa fa-trash-o"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex tbl-foot-bx">
                                <div className="mr5">
                                    <Paging fn={fn.lists} pageob={d.list.page} />
                                </div>
                                <div>
                                    <Perpagedd fn={fn.lists} />
                                </div>
                            </div>
                        </If>
                        <If cond={!d.list.result.length}>
                            <div className="no-rec">No record found</div>
                        </If>
                    </div>
                </div>
            </div>

            {/** Form */}
            <div className="modal modal-scroll" id="formModal" data-backdrop="static">
                <div className="modal-dialog modal-full" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <div className="modal-title uc">{d.data.id?'Edit':'Add'} Program</div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={fn.pauseVideo}><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <form id="frm" autoComplete="off" spellCheck="false">
                                <input type="hidden" name="id" defaultValue={d.data.id} />
                                <div className="row mingap">
                                    <div className="form-group col-md-9">
                                        <label className="req">Program Name</label>
                                        <input type="text" className="form-control" name="title" value={d.data.title || ''} onChange={fn.handleChange} />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label className="req">Price</label>
                                        <input type="number" className="form-control" name="price" value={d.data.price || ''} onChange={fn.handleChange} />
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label className="">Description</label>
                                        <textarea rows="4" className="form-control" name="description" value={d.data.description || ''} onChange={fn.handleChange}></textarea>
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label className="req">Expiry Days</label>
                                        <input type="number" className="form-control" name="expiry_days" value={d.data.expiry_days || ''} onChange={fn.handleChange} />
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label className="req">Is New</label>
                                        <select className="form-control" name="is_new" value={d.data.is_new || ''} onChange={fn.handleChange}>
                                            <option value="1">YES</option>
                                            <option value="0">NO</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label className="req">Tag</label>
                                        <input type="text" className="form-control" name="tag" value={d.data.tag || ''} onChange={fn.handleChange} />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label className="req">Image (Max size 1MB)</label>
                                        <input type="file" className="form-control" name="image" />
                                        {d.data.image_url && <div className="pt5"><img src={d.data.image_url} className="w100" /></div>}
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label className="req">Icon (Max size 1MB)</label>
                                        <input type="file" className="form-control" name="icon" />
                                        {d.data.icon_url && <div className="pt5"><img src={d.data.icon_url} className="w40" /></div>}
                                    </div>

                                    <div className="form-group col-md-12">
                                        <div className="row mingap">
                                            <div className="col-md-8">
                                                <div className="mb10">
                                                    <label className="">Summary (Above Video)</label>
                                                    <textarea rows="4" className="form-control" name="summary_text1" value={d.data.summary_text1 || ''} onChange={fn.handleChange}></textarea>
                                                </div>
                                                <div>
                                                    <label className="">Summary (After Video)</label>
                                                    <textarea rows="4" className="form-control" name="summary_text2" value={d.data.summary_text2 || ''} onChange={fn.handleChange}></textarea>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="mb10">
                                                    <label className="">Summary Video (Vimeo Url)</label>
                                                    <input type="text" className="form-control" name="summary_video_vimeo" value={d.data.summary_video_vimeo || ''} onChange={fn.handleChange} />
                                                    <If cond={d.data.vimeo_id}>
                                                        <div className="mt5">
                                                            <VimeoVideo videoid={d.data.vimeo_id} />
                                                        </div>
                                                    </If>
                                                </div>

                                                <label className="">Summary Video (Max size 500MB)</label>
                                                <input type="file" className="form-control" name="summary_video" />
                                                <If cond={d.data.summary_video_url}>
                                                    <div className="pt5">
                                                        <div className="position-relative" style={{fontSize:0, height:'140px'}}>
                                                            <Htmlvideo className="vplayer1 frmvp" src={d.data.summary_video_url} />
                                                        </div>
                                                    </div>
                                                </If>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="form-group col-md-12">
                                        <label className="">Mark Division</label>
                                        <Tinymce id="te1" height="300" name="summary_mark_division" data={d.data.summary_mark_division} />
                                    </div>
                                    
                                    <div className="form-group col-md-2">
                                        <label className="">Display Order</label>
                                        <input type="number" className="form-control" name="disp_odr" value={d.data.disp_odr || ''} onChange={fn.handleChange} />
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label className="req">Status</label>
                                        <select className="form-control" name="status" value={d.data.status || ''} onChange={fn.handleChange}>
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </select>
                                    </div>
                                    
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={fn.pauseVideo}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={fn.save}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        
        </>
    )
}