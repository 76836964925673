import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import util from "../../util/util";
import {If} from "../../util/controls";

export default function Header(props) {
    const [d, setData]=useState({
        is_admin:false,
        modules:[],
    });
    const init=()=>{
        util.ajx("auth/logged_data").then(res=>{
            if(res.code===200){
                setData({...d, is_admin:res.is_admin, modules:res.modules});
            }
        })
    }
    useEffect(()=>{init();}, []);

    return (
        <>
            <nav className="navbar navbar-expand-sm sticky-top1 navbar-dark navbar-bg-blue">
                <Link className="navbar-brand" to="/dashboard">
                    {/* <img src="assets/img/bootstrap-solid.svg" width="32" height="32" alt="" /> */}
                    <span style={{color:'#df4658', textShadow: 'rgb(255, 0, 0) 0px 0px 20px'}}>LEARN-NATA</span>
                </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto uc">
                        <li className="nav-item active1">
                            <Link className="nav-link" to="/dashboard">Dashboard</Link>
                        </li>

                        <If cond={d.modules['manage_roles'] || d.modules['manage_users']}>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle uc" to="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Manage Users
                                </Link>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                    <If cond={d.modules['manage_roles']}><Link className="dropdown-item" to="/role">Manage Roles</Link></If>
                                    <If cond={d.modules['manage_users']}><Link className="dropdown-item" to="/users">Manage Users</Link></If>
                                </div>
                            </li>
                        </If>

                        <If cond={d.modules['manage_programs']}>
                            <li className="nav-item">
                                <Link className="nav-link" to="/programs">Programs</Link>
                            </li>
                        </If>
                        
                        <If cond={d.modules['manage_course']}>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle uc" to="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Courses
                                </Link>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                    <Link className="dropdown-item" to="/courses">Courses</Link>
                                    <Link className="dropdown-item" to="/course-modules">Modules</Link>
                                    <Link className="dropdown-item" to="/sessions">Sessions</Link>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle uc" to="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Assignments
                                </Link>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                    <Link className="dropdown-item" to="/assignments">Assignments</Link>
                                    <Link className="dropdown-item" to="/assignment-modules">Modules</Link>
                                    <Link className="dropdown-item" to="/questions">Questions</Link>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle uc" to="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Mock Tests
                                </Link>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                    <Link className="dropdown-item" to="/tests">Mock Tests</Link>
                                    <Link className="dropdown-item" to="/test-modules">Modules</Link>
                                    <Link className="dropdown-item" to="/test-questions">Questions</Link>
                                </div>
                            </li>
                        </If>

                        <If cond={d.modules['manage_smtp'] || d.modules['home_page_settings'] || d.modules['manage_cms_pages']}>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle uc" to="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Settings
                                </Link>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                    <If cond={d.modules['home_page_settings']}><Link className="dropdown-item" to="/home-page-settings">Home Page</Link></If>
                                    <If cond={d.modules['manage_cms_pages']}><Link className="dropdown-item" to="/cms-pages">CMS Pages</Link></If>
                                    <If cond={d.modules['manage_smtp']}><Link className="dropdown-item" to="/smtp">SMTP</Link></If>
                                </div>
                            </li>
                        </If>

                        <If cond={d.modules['student_panel']}>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle uc" to="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Student Panel
                                </Link>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                    <Link className="dropdown-item" to="/students">Students</Link>
                                    <Link className="dropdown-item" to="/payments">Payment History</Link>
                                    <Link className="dropdown-item" to="/newsletters">Newsletters</Link>
                                    <div className="dropdown-divider"></div>
                                    <Link className="dropdown-item" to="/notifications">Notifications</Link>
                                    <Link className="dropdown-item" to="/coupons">Coupons</Link>
                                    <Link className="dropdown-item" to="/resources">Resources</Link>
                                </div>
                            </li>
                        </If>
                    </ul>

                    
                    <ul className="navbar-nav user-nav">
                        {/* <li className="nav-item">
                            <Link className="nav-link noti-bell-bx" to="">
                                &nbsp;<i className="fa fa-bell"></i>&nbsp;
                            </Link>
                        </li> */}
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle uc" to="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fa fa-user"></i> {util.getLoggedName()}
                            </Link>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                                {/* <Link className="dropdown-item" to="#">Profile</Link>
                                <Link className="dropdown-item" to="#">Settings</Link> */}
                                {/* <div className="dropdown-divider"></div> */}
                                <Link className="dropdown-item" to="change-password">Change Password</Link>
                                <a className="dropdown-item" href="/" onClick={(e)=>util.logout(e, props.history)}>Logout</a>
                            </div>
                        </li>
                    </ul>
                    <form className="form-inline my-2 my-lg-0 text-white">
                    </form>
                </div>
            </nav>
        </>
    );
}