import React, {useState, useEffect} from 'react';
import util from "../../util/util";
import {If, Paging, Perpagedd, Tinymce, SubmitTinymce, SetTinymceContent} from "../../util/controls";
let $=window.$;

let mRob={};

export default function Questions(props) {
    const [d, setData]=useState({
        list:{result:[], page:{}}, 
        programs:[], 
        courses:[],
        modules:[],
        selPrgName:'', 
        selCourseName:'',
        selModuleName:'',
        ansTypes: {},
        sdata:{p:1, ps:25, program_id:0, course_id:0, module_id:0, session_id:'', type:props.type},
        data:{},
        sections:[]
    });
    const fn={};
    fn.render=()=>{setData({...d})}

    fn.handleChange=(e, dobj)=>{
        let {name, value}=e.target;
        if(name==='correct_option'){
            if(d.data.ans_type==='Option_Multi'){
                if(e.target.checked){
                    d.data.correct_option.push(value);
                }else{
                    d.data.correct_option.splice(d.data.correct_option.indexOf(value), 1);
                }
            }else{
                d.data.correct_option=[value];
            }
        }else if(name==='ans_type' && ['Option_Single', 'Option_Multi'].indexOf(value) > -1){
            d.data.correct_option=[];
            d.data[name]=value;
        }else{
            if(!dobj){
                dobj=d.data;
            }
            dobj[name]=value;
        }
        fn.render();
    }

    fn.selectProgram=(prg_id, title)=>{
        d.sdata.program_id=prg_id;
        d.selPrgName=title;
        d.sdata.course_id=0;
        d.selCourseName='';
        d.sdata.module_id=0;
        d.selModuleName='';
        fn.render();
    }
    fn.selectCourse=(course_id, title)=>{
        d.sdata.course_id=course_id;
        d.selCourseName=title;
        d.sdata.module_id=0;
        d.selModuleName='';
        fn.render();
    }
    fn.selectModule=(rob)=>{
        mRob=rob;
        d.sdata.module_id=rob.id;
        d.selModuleName=rob.title;
        d.sdata.s='';
        util.ajx("master/course_module_sections/" + rob.id).then(res =>{
            if(res.code == 200){
                d.sections=res.result;
                fn.lists();
            }
        })
    }

    fn.openForm=(dtl)=>{
        d.data=dtl?{...dtl}:{module_id:d.sdata.module_id, session_id:d.sdata.session_id, ans_type:'Option_Single', no_of_option:4, correct_option:[]};
        fn.render();
        window.showModal($("#quesformModal"), true);
    }

    const sdata=(p, ps)=>{
        d.sdata.p=p?p:1;
        d.sdata.ps=ps?ps:d.sdata.ps;
        return d.sdata; //$("#ques_sfrm").serialize()+'&p='+d.sdata.p+'&ps='+d.sdata.ps+'&module_id='+d.module_id;
    }
    fn.lists=(e, p, ps)=>{
        if(e)e.preventDefault();
        util.ajx("master/questions", sdata(p, ps)).then(res=>{
            if(res.code===200){
                d.list=res.result;
                fn.render();
            }
        })
    }

    fn.save=()=>{
        SubmitTinymce("te1");
        let fd=new FormData($("#ques_frm")[0]);
        fd.set('correct_option',d.data.correct_option)
        util.ajx("master/save_question", fd).then(res=>{
            if(res.code===200){
                if(!d.data.id){
                    mRob['countques']+=1;
                }
                SetTinymceContent("te1", "");
                d.data={};
                fn.lists(0, d.data.id?d.sdata.p:1);
                window.hideModal($("#quesformModal"));
            }
        })
    }

    fn.deleterec=(id)=>{
        let callback=()=>{
            util.ajx("master/delete_question", {id:id}).then(res=>{
                if(res.code===200){
                    mRob['countques']-=1;
                    fn.lists();
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);
    }

    fn.progCcountFeild=()=>{
        return props.type==='C'?'countc':(props.type=='A'?'counta':'countt');
    }
    fn.getPcountLabel=(flg)=>{
        return (props.type==='C'?'Course':(props.type=='A'?'Assignment':'Mock Test'))+(flg?'s':'');
    }
   
    fn.clearForm=()=>{
        SetTinymceContent("te1", "");
        d.data={};
        fn.render();
    }

    fn.init=()=>{
        util.ajx("master/init_question", d.sdata).then(res=>{
            if(res.code === 200){
                res.modules.forEach((v)=>{
                    if(d.sdata.module_id===v.id){
                        d.selModuleName=v.title;
                        d.sdata.course_id=v.course_id;
                        d.sdata.program_id=v.program_id;
                        mRob=v;
                    }
                })
                res.courses.forEach((v)=>{
                    if(d.sdata.course_id===v.id){
                        d.selCourseName=v.title;
                    }
                })
                res.programs.forEach((v)=>{
                    if(d.sdata.program_id===v.id){
                        d.selPrgName=v.title;
                    }
                })

                setData({...d, sections:res.sections, list:res.result, programs:res.programs, courses:res.courses, modules:res.modules, ansTypes:res.ans_types});
            }
        });
    }
    

    useEffect(()=>{
        let module_id=props.module_id || '';
        if(!module_id){
            module_id=props.computedMatch.params.module_id || '';
        }
        let session_id=props.session_id || '';
        d.sdata={...d.sdata, module_id:module_id, type:props.type, session_id:session_id}
        fn.init();
    }, [props.type]);

    let session_id=props.session_id || '';
    return session_id?quesList(d, fn):List(d, fn);
}

function List(d, fn){
    let cf=fn.progCcountFeild();
    return(<>
            <div className="page-head sm">
                <div className="container-fluid d-flex">
                    <h5 className="mt-auto mb-auto pr15">{fn.getPcountLabel(1)} Questions</h5>
                    <div className="mt-auto mb-auto font-xs text-secondary">
                        {d.selPrgName && <span className="bsep">{d.selPrgName}</span>}
                        {d.selCourseName && <span className="bsep"> {d.selCourseName}</span>}
                        <span className="bread-act"> {d.selModuleName}</span>
                    </div>
                    <div className="ml-auto mt-auto mb-auto">
                        <button className="btn btn-sm btn-outline-secondary bdr-radius-0" onClick={()=>window.history.back()}><i className="fa fa-chevron-circle-left"></i> Back</button>
                    </div>
                </div>
            </div>

            <div className="main-content mc-full-height">
                <div className="container-fluid h-100">
                    <div className="d-flex h-100">
                        <div className="plist-card w200">
                            <div className="plist-card-head">Programs</div>
                            <div className="plist-card-body">
                                <ul className="items-ul">
                                    <ul className="items-ul">
                                        {d.programs.map(r=>(
                                        <li key={r.id} className={r.id===d.sdata.program_id?'active':''} onClick={()=>fn.selectProgram(r.id, r.title)}>
                                            {r.title}
                                            <div className="pt3 note-text">{r[cf]+' '+fn.getPcountLabel(1)}</div>
                                        </li>))}
                                    </ul>
                                </ul>
                            </div>
                        </div>
                        <div className="plist-card w200">
                            <div className="plist-card-head">{d.sdata.type=='A'?'Assignments':'Mock Tests'}</div>
                            <div className="plist-card-body">
                                <ul className="items-ul">
                                    {d.courses.map(r=>(
                                        <If key={r.id} cond={r.program_id===d.sdata.program_id}>
                                            <li className={r.id===d.sdata.course_id?'active':''} onClick={()=>fn.selectCourse(r.id, r.title)}>
                                                {r.title}
                                                <div className="pt3 note-text">{r['countm']} Modules</div>
                                            </li>
                                        </If>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="plist-card w260">
                            <div className="plist-card-head">Modules</div>
                            <div className="plist-card-body">
                                <ul className="items-ul">
                                    {d.modules.map(r=>(
                                        <If key={r.id} cond={r.course_id===d.sdata.course_id}>
                                            <li className={r.id===d.sdata.module_id?'active':''} onClick={()=>fn.selectModule(r)}>
                                                {r.title}
                                                <div className="pt3 note-text">{r['countques']+' Questions'}</div>
                                            </li>
                                        </If>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="plist-card w100-670">
                            <div className="plist-card-head">Questions <span className="text-secondary">/ {d.selModuleName}</span></div>
                            <div className="plist-card-body">
                                <If cond={!d.sdata.module_id}><div className="p30 text-center text-secondary">Select program / {fn.getPcountLabel()} / module to see questions</div></If>

                                <div className="p8">
                                    {quesList(d, fn)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

function quesList(d, fn){
    return (
        <div>
            <If cond={d.sdata.module_id}>
                <If cond={d.list.result.length}>
                    <div className="d-flex">
                        <div className="text-secondary bold600 mb5">
                            Showing {d.list.page.start+1} - {d.list.page.start+d.list.page.total} of {d.list.page.total_records} records.
                        </div>
                    </div>
                </If>
                <div className="d-flex tbl-search-head">
                    <div className="form-inline">
                        <form id="ques_sfrm" onSubmit={fn.lists} autoComplete="off" spellCheck="false">
                            <div className="input-group">
                                <input type="search" className="form-control" name="k" placeholder="Search" value={d.sdata.k || ''} onChange={e=>fn.handleChange(e, d.sdata)} />
                                <If cond={d.sdata.type==='T'}>
                                    <select className="form-control custom-select ml-2" name="s" value={d.sdata.s || ''} onChange={e=>fn.handleChange(e, d.sdata)}>
                                        <option value="">Select Section</option>
                                        {d.sections.map((val, key) => <option key={val.id} value={val.id}>{val.title}</option>)}
                                    </select>
                                </If>
                                <div className="input-group-append ml-2">
                                    <button className="btn btn-light-blue" type="submit"><i className="fa fa-search"></i> Search</button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="ml-auto mt-auto mb-auto">
                        <button className="btn btn-primary" onClick={()=>fn.openForm()}><i className="fa fa-plus"></i> Add</button>
                    </div>
                </div>

                <If cond={d.list.result.length}>
                    <div className="table-responsive">
                        <table className="table table-bordered table-sm table-striped table-hover m-0">
                            <thead className="thead-light text-uppercase table-text-vmid font-sm">
                                <tr>
                                    <th className="w20">SN</th>
                                    <th>Question Title</th>
                                    <th className="w120 nowrap">Answer Type</th>
                                    <th className="w80">Status</th>
                                    <th className="w70"></th>
                                </tr>
                            </thead>

                            <tbody className="">
                                {d.list.result.map((v, i) => (
                                    <tr key={i}>
                                        <td>{d.list.page.start + i + 1}.</td>
                                        {/* <td className="mw-100"><RawHTML html={v.question} /></td> */}
                                        <td>
                                            {v.title}
                                            <div className="note-text">
                                                <div className="inblock w60">Disp. Order</div> : <strong>{v.disp_odr}</strong>
                                                <If cond={d.sdata.type==='T'}>
                                                    <div className="inblock pl30"><div className="inblock w30">Section</div> : <strong>{v.section}</strong></div>
                                                </If>
                                            </div>
                                        </td>
                                        <td className="nowrap">
                                            {d.ansTypes[v.ans_type]}
                                            <div className="note-text">Marks: <strong>{v.marks*1 || 'NA'}</strong></div>
                                        </td>
                                        <td>
                                            <If cond={v.status === '1'}>
                                                <div className="badge badge-success">Active</div>
                                            </If>
                                            <If cond={v.status === '0'}>
                                                <div className="badge badge-danger">Inactive</div>
                                            </If>
                                        </td>
                                        <td className="text-center">
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-sm btn-light-blue gr" onClick={() => fn.openForm(v)}><i className="fa fa-edit"></i></button>
                                                <button type="button" className="btn btn-sm btn-danger gr" onClick={() => fn.deleterec(v.id)}><i className="fa fa-trash-o"></i></button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="d-flex tbl-foot-bx">
                        <div className="mr5">
                            <Paging fn={fn.lists} pageob={d.list.page} />
                        </div>
                        <div>
                            <Perpagedd fn={fn.lists} />
                        </div>
                    </div>
                </If>
                <If cond={!d.list.result.length}>
                    <div className="no-rec">No record found</div>
                </If>
            </If>

            {Form(d, fn)}
        </div>
    )
}

function Form(d, fn){
    return(
        <div className="modal no-backdrop" id="quesformModal" data-backdrop="static">
            <div className="modal-dialog modal-l" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">{d.data.id ? 'Edit' : 'Add'} Question</div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={fn.clearForm}><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <If cond={!d.sdata.session_id}>
                            <div className="mb10 form-control bg-light">
                                {d.selPrgName && <span className="bsep">{d.selPrgName}</span>}
                                {d.selCourseName && <span className="bsep"> {d.selCourseName}</span>}
                                <span className="bread-act"> {d.selModuleName}</span>
                            </div>
                        </If>

                        <form id="ques_frm" autoComplete="off" spellCheck="false">
                            <input type="hidden" name="id" defaultValue={d.data.id} />
                            <input type="hidden" name="module_id" defaultValue={d.data.module_id} />
                            <input type="hidden" name="session_id" defaultValue={d.data.session_id} />
                            <div className="row mingap">
                                <div className={"form-group col-md-"+(d.sdata.type==='T'?'10':'12')}>
                                    <label className="req">Title (Only for admin)</label>
                                    <div>
                                        <input type="text" className="form-control" name="title" value={d.data.title || ''} onChange={fn.handleChange} />
                                    </div>
                                </div>
                                <If cond={d.sdata.type==='T'}>
                                    <div className="col-md-2 form-group">
                                        <label className="req">Section</label>
                                        <div>
                                        <select className="form-control custom-select" name="section_id" value={d.data.section_id || ''} onChange={fn.handleChange}>
                                            <option value="">Select Section</option>
                                            {d.sections.map((val, key) => <option key={val.id} value={val.id}>{val.title}</option>)}
                                        </select>
                                        </div>
                                    </div>
                                </If>
                                <div className="col-md-12 form-group">
                                    <label className="req">Question</label>
                                    <div>
                                        <Tinymce id="te1" height="300" name="question" data={d.data.question} />
                                    </div>
                                </div>

                                <div className="col-md-12 form-group">
                                    <div className="table-responsive border noselect">
                                        <table className="table table-bordered1 table-borderless table-sm m-0">
                                            <tbody className="table-text-vmid font-sm border-0">
                                                <tr>
                                                    <td className="w150 req bg-light">Answer Type</td>
                                                    <td className="overflow-hidden">
                                                        <div className="row mingap">
                                                            <div className="col-md-9">
                                                                <select className="form-control custom-select" name="ans_type" value={d.data.ans_type || ''} onChange={fn.handleChange}>
                                                                    {Object.keys(d.ansTypes).map((key) => <option key={key} value={key}>{d.ansTypes[key]}</option>)}
                                                                </select>
                                                            </div>
                                                            {(['Option_Single', 'Option_Multi'].indexOf(d.data.ans_type) > -1) && 
                                                            <div className="col-md-3">
                                                                <select className="form-control custom-select" name="no_of_option" value={d.data.no_of_option} onChange={fn.handleChange}>
                                                                    <option value="">No. of Options</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                </select>
                                                            </div>}
                                                        </div>
                                                    </td>
                                                </tr>
                                                {(['Option_Single', 'Option_Multi'].indexOf(d.data.ans_type) > -1) && <>
                                                    {[1, 2, 3, 4, 5, 6].map(function (key) {
                                                        if (key > d.data.no_of_option) {
                                                            return false;
                                                        }
                                                        let name = "option" + key;
                                                        let checked = (d.data.correct_option.indexOf(key.toString()) > -1) ? true : false;

                                                        return <tr key={key}>
                                                            <td className="req bg-light">Option {key}</td>
                                                            <td className="">
                                                                <div className="input-group">
                                                                    <input type="text" className="form-control" name={name} value={d.data[name] || ''} onChange={fn.handleChange} />
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text">
                                                                            <label className="m-0">
                                                                                <input type={d.data.ans_type === 'Option_Single' ? 'radio' : 'checkbox'} name="correct_option" onChange={fn.handleChange} checked={checked} value={key} />
                                                                                &nbsp;Correct
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </>}
                                                
                                                {(d.data.ans_type == 'Text' || d.data.ans_type == 'Text_Image') &&
                                                <tr>
                                                    <td className="w140 req bg-light">Correct Answer</td>
                                                    <td>
                                                        <textarea className="form-control" name="correct_ans" onChange={fn.handleChange} value={d.data.correct_ans || ''}></textarea>
                                                    </td>
                                                </tr>}

                                                {(d.data.ans_type == 'Image' || d.data.ans_type == 'Text_Image') && 
                                                <tr>
                                                    <td className="w140 req bg-light">Upload Answer Image</td>
                                                    <td>
                                                        <input type="file" name="correct_ans_img" className="form-control" />
                                                        {d.data.correct_ans_img &&
                                                        <div className="pt5">
                                                            <a href={d.data.correct_ans_img} target="_blank">
                                                                <img className="img img-thumbnail w120" src={d.data.correct_ans_img} title='Correct Answer Image' />
                                                            </a>
                                                        </div>}
                                                    </td>
                                                </tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                                            
                                <If cond={d.sdata.type==='T'}>
                                    <div className="form-group col-md-2">
                                        <label className="req">Marks</label>
                                        <input type="number" className="form-control" name="marks" value={d.data.marks || ''} onChange={fn.handleChange} />
                                    </div>
                                </If>
                                
                                <div className="form-group col-md-2">
                                    <label className="">Display Order</label>
                                    <input type="text" className="form-control" name="disp_odr" value={d.data.disp_odr || ''} onChange={fn.handleChange} />
                                </div>

                                <div className="form-group col-md-2">
                                    <label className="req">Status</label>
                                    <select className="form-control custom-select" name="status" value={d.data.status || ''} onChange={fn.handleChange}>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={fn.clearForm}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={fn.save}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}