import React from 'react';
import {Switch} from 'react-router-dom';
import DefaultLayout from '../views/layouts/default';
import LoginLayout from '../views/layouts/login';

import ChangePassword from "../views/auth/change_password";
import Login from "../views/auth/login";
import Dashboard from "../views/dashboard";
import Roles from "../views/master/Roles";
import Users from "../views/master/Users";
import Programs from "../views/master/Programs";

import Courses from "../views/master/Courses";
import CourseModules from "../views/master/CourseModules";

import Sessions from "../views/master/Sessions";
import Questions from "../views/master/Questions";

import HomePageSettings from '../views/master/HomePageSettings';
import Smtp from "../views/master/Smtp";
import CmsPages from "../views/master/CmsPages";
import Students from "../views/master/Students";
import Payments from "../views/master/Payments";
import Newsletters from "../views/master/Newsletters";

import Notifications from "../views/master/Notifications";
import Coupons from "../views/master/Coupons";
import Resources from "../views/master/Resources";

export default function Routes() {
    return (
        <Switch>
            <LoginLayout path="/" exact component={Login} />
            <DefaultLayout path="/change-password" component={ChangePassword} />
            <DefaultLayout path="/dashboard" component={Dashboard} />
            <DefaultLayout path="/role" component={Roles} />
            <DefaultLayout path="/users" component={Users} />
            <DefaultLayout path="/programs" component={Programs} />
            
            <DefaultLayout path="/courses" exact type="C" component={Courses} />
            <DefaultLayout path="/courses/:program_id" type="C" component={Courses} />
            <DefaultLayout path="/assignments" exact type="A" component={Courses} />
            <DefaultLayout path="/assignments/:program_id" type="A" component={Courses} />
            <DefaultLayout path="/tests" exact type="T" component={Courses} />
            <DefaultLayout path="/tests/:program_id" type="T" component={Courses} />

            <DefaultLayout path="/course-modules" exact type="C" component={CourseModules} />
            <DefaultLayout path="/course-modules/:course_id" type="C" component={CourseModules} />
            <DefaultLayout path="/assignment-modules" exact type="A" component={CourseModules} />
            <DefaultLayout path="/assignment-modules/:course_id" type="A" component={CourseModules} />
            <DefaultLayout path="/test-modules" exact type="T" component={CourseModules} />
            <DefaultLayout path="/test-modules/:course_id" type="T" component={CourseModules} />

            <DefaultLayout path="/sessions" exact component={Sessions} />
            <DefaultLayout path="/sessions/:module_id" component={Sessions} />
            
            <DefaultLayout path="/questions" exact type="A" component={Questions} />
            <DefaultLayout path="/questions/:module_id" type="A" component={Questions} />
            <DefaultLayout path="/test-questions" exact type="T" component={Questions} />
            <DefaultLayout path="/test-questions/:module_id" type="T" component={Questions} />

            <DefaultLayout path="/home-page-settings" component={HomePageSettings} />
            <DefaultLayout path="/smtp" component={Smtp} />
            <DefaultLayout path="/cms-pages" component={CmsPages} />
            <DefaultLayout path="/students" component={Students} />
            <DefaultLayout path="/payments" component={Payments} />
            <DefaultLayout path="/newsletters" component={Newsletters} />
            <DefaultLayout path="/notifications" component={Notifications} />
            <DefaultLayout path="/coupons" component={Coupons} />
            <DefaultLayout path="/resources" component={Resources} />
        </Switch>
      );
}