import React, {useState, useEffect} from 'react';
import util from "../util/util";
import {If, Paging, Perpagedd} from "../util/controls";
let $=window.$;

let cRob={};
export default function Dashboard() {
    const fn={};
    const [d, setData]=useState({
        list:{result:[], page:{}},
        replies:[],
        sdata:{p:1, ps:25},
        data:{}
    });

    fn.render=()=>{
        setData({...d});
    }

    fn.handleChange=(e)=>{
        d.data[e.target.name]=e.target.value;
        fn.render();
    }

    fn.openForm=(dtl)=>{
        d.data=dtl?{...dtl}:{};
        fn.render();
        window.showModal($("#formModal"));
    }

    fn.sdata=(p, ps)=>{
        d.sdata.p=p?p:1;
        d.sdata.ps=ps?ps:d.sdata.ps;
        return $("#sfrm").serialize()+'&p='+d.sdata.p+'&ps='+d.sdata.ps;
    }
    fn.lists=(e, p, ps)=>{
        if(e)e.preventDefault();
        util.ajx("student/chats", fn.sdata(p, ps)).then(res=>{
            if(res.code===200){
                d.list=res.result;
                fn.render();
            }
        })
    }

    fn.openChatReply=(rob)=>{
        cRob=rob;
        util.ajx("student/last_chats", {chat_id:rob.id, stu_id:rob.student_id, student_name:rob.student_name, stu_email:rob.student_name}).then(res=>{
            if(res.code===200){
                d.replies=res.result;
                rob.isread=1;
                fn.render();
                window.showModal($("#replyModal"), true);
                setTimeout(()=>{$(".sticky-tbl").scrollTop($(document).height());}, 0);
            }
        })
    }

    fn.save_chat_reply=(e)=>{
        e.preventDefault();
        let msg=$.trim($("#chat_msg_input").val());
        if(!msg){
            $("#chat_msg_input").focus();
            return;
        }

        util.ajx("student/save_chat_reply", {...cRob, msg:msg}).then(res=>{
            if(res.code===200){
                d.replies=res.result;
                fn.render();
                $("#chat_msg_input").val('');
                setTimeout(()=>{$(".sticky-tbl").scrollTop($(document).height());}, 0);
            }
        })
    }
    
    fn.init=()=>{
        fn.lists();
    }
    useEffect(()=>{fn.init();}, []);

    return View(d, fn);
}

function View(d, fn){
    return(
        <>
            <div className="page-head">
                <div className="container-fluid d-flex">
                    <h5 className="mt-auto mb-auto">Dashboard: Student Queries</h5>
                    <div className="ml-auto mt-auto mb-auto">
                    </div>
                </div>
            </div>

            <div className="main-content">
                <div className="container-fluid">
                    <If cond={d.list.result.length}>
                        <div className="d-flex">
                            <div className="text-secondary bold600 mb5">
                                Showing {d.list.page.start+1} - {d.list.page.start+d.list.page.total} of {d.list.page.total_records} records.
                            </div>
                        </div>
                    </If>

                    <div className="d-flex tbl-search-head">
                        <div className="form-inline">
                            <form id="sfrm" onSubmit={fn.lists} autoComplete="off" spellCheck="false">
                                <div className="input-group">
                                    <input type="search" className="form-control" name="k" placeholder="Search" />
                                    <div className="input-group-append">
                                        <button className="btn btn-light-blue" type="submit"><i className="fa fa-search"></i></button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="ml-auto mt-auto mb-auto">
                        </div>
                    </div>

                    <div>
                        <If cond={d.list.result.length}>
                            <div className="table-responsive">
                                <table className="table table-bordered table-sm table-striped table-hover m-0">
                                    <thead className="thead-light text-uppercase table-text-vmid font-sm">
                                        <tr>
                                            <th className="w20">SN</th>
                                            <th>Query</th>
                                            <th className="w80"></th>
                                            <th className="w100">Sent On</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-text-top">
                                        {d.list.result.map((v, i)=>(
                                            <tr key={i}>
                                                <td>{d.list.page.start+i+1}.</td>
                                                <td>
                                                    <div className={v.isread?'':'bold600'}>{v.msg}</div>
                                                    <div className="note-text pt3">Student: <strong>{v.student_name} [{v.stu_email}]</strong></div>
                                                </td>
                                                <td>
                                                    <button type="button" className="btn btn-sm btn-warning nowrap btn-block" onClick={()=>fn.openChatReply(v)}>Reply</button>
                                                </td>
                                                <td className="nowrap">{v.created}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex tbl-foot-bx">
                                <div className="mr5">
                                    <Paging fn={fn.lists} pageob={d.list.page} />
                                </div>
                                <div>
                                    <Perpagedd fn={fn.lists} />
                                </div>
                            </div>
                        </If>
                        <If cond={!d.list.result.length}>
                            <div className="no-rec">No record found</div>
                        </If>
                    </div>
                </div>
            </div>

            {/** Form */}
            <div className="modal modal-scroll" id="replyModal" data-backdrop="static">
                <div className="modal-dialog modal-l" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <div className="modal-title uc">Chat: Queries</div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-control bg-light mb10">
                                <span className="bread-act">Student: {cRob.student_name} [{cRob.stu_email}]</span>
                            </div>

                            <div className="sticky-tbl" style={{maxHeight:'430px'}}>
                                <div className="pos-sticky-1px"></div>
                                <table className="table table-bordered table-sm table-striped1 table-hover m-0">
                                    <thead className="thead-light text-uppercase table-text-vmid font-sm">
                                        <tr>
                                            <th>Message</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-text-top">
                                        {d.replies.map((v, i)=>(
                                            <tr key={i}>
                                                <td>
                                                    <div className="pt5 pb5 pl10 pr10">
                                                        <div className={v.msg_from==='Admin'?'text-right pl100':'pr100'}>
                                                            <div>{v.msg}</div>
                                                            <div className="note-text pt3">By {v.msg_from==='Admin'?'Learnnata Admin':'Student'} @ {v.created}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="pt15 pb5">
                                <div className="p10 bg-light">
                                    <form onSubmit={e=>fn.save_chat_reply(e)}>
                                        <div className="input-group text-btn">
                                            <input type="text" id="chat_msg_input" className="form-control" placeholder="Type your message...." maxLength="255" />
                                            <div className="input-group-append">
                                                <button className="btn btn-primary" type="submit"><i className="fa fa-paper-plane"></i></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}