import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import util from "../../util/util";
import {If, Paging, Perpagedd} from "../../util/controls";
let $=window.$;

let pRob={};

export default function Courses(props){
    const [d, setData]=useState({
        list:{result:[], page:{}}, 
        programs:[], 
        selPrgName:'', 
        sdata:{p:1, ps:25, type:props.type, program_id:0},
        data:{},
    });
    const fn={};
    fn.render=()=>{setData({...d})}
    
    fn.handleChange=(e, dobj)=>{
        if(!dobj){
            dobj=d.data;
        }
        dobj[e.target.name]=e.target.value;
        setData({...d});
    }

    fn.sdata=(p, ps)=>{
        d.sdata.p=p?p:1;
        d.sdata.ps=ps?ps:d.sdata.ps;
        return $("#sfrm").serialize()+'&'+$.param(d.sdata);
    }
    fn.lists=(e, p, ps)=>{
        if(e)e.preventDefault();
        util.ajx("master/courses", fn.sdata(p, ps)).then(res=>{
            if(res.code===200){
                setData({...d, list:res.result});
            }
        })
    }
    
    fn.selectProgram=(rob)=>{
        pRob=rob;
        d.sdata.program_id=rob.id;
        d.selPrgName=rob.title;
        fn.lists();
    }

    fn.openForm=(dtl)=>{
        setData({...d, data:dtl?{...dtl}:{program_id:d.sdata.program_id, type:d.sdata.type}});
        window.showModal($("#formModal"));
    }

    fn.save=()=>{
        let fd=new FormData($("#frm")[0]);
        util.ajx("master/save_course", fd).then(res=>{
            if(res.code===200){
                window.hideModal($("#formModal"));
                if(!d.data.id){
                    pRob[fn.ccountFeild()]+=1;
                }
                fn.lists(0, d.data.id?d.sdata.p:1);
            }
        })
    }

    fn.deleterec=(id)=>{
        let callback=()=>{
            util.ajx("master/delete_course", {id:id}).then(res=>{
                if(res.code===200){
                    pRob[fn.ccountFeild()]-=1;
                    fn.lists();
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);
    }

    fn.getPageName=(flg)=>{
        return (props.type==='C'?'Course':(props.type=='A'?'Assignment':'Mock Test'))+(flg?'s':'');
    }
    fn.ccountFeild=()=>{
        return props.type==='C'?'countc':(props.type=='A'?'counta':'countt');
    }

    fn.init=()=>{
        util.ajx("master/init_course", d.sdata).then(res=>{
            if(res.code === 200){
                let selPrgName='';
                res.programs.forEach((v)=>{
                    if(d.sdata.program_id===v.id){
                        selPrgName=v.title;
                        pRob=v;
                    }
                })
                setData({...d, list:res.result, programs:res.programs, selPrgName:selPrgName});
            }
        });
    }

    useEffect(()=>{
        let program_id=props.computedMatch.params.program_id || '';
        d.sdata={...d.sdata, program_id:program_id, type:props.type}
        fn.init();
    }, [props.type]);

    return List(d, fn);
}

function List(d, fn){
    let pageName=fn.getPageName();
    let pageNames=fn.getPageName(true);
    let cf=fn.ccountFeild();

    return(<>
            <div className="page-head sm">
                <div className="container-fluid d-flex">
                    <h5 className="mt-auto mb-auto pr15">{pageNames}</h5>
                    <div className="mt-auto mb-auto font-xs text-secondary">
                        <span className="bread-act">{d.selPrgName}</span>
                    </div>
                    <div className="ml-auto mt-auto mb-auto">
                        <button className="btn btn-sm btn-outline-secondary bdr-radius-0" onClick={()=>window.history.back()}><i className="fa fa-chevron-circle-left"></i> Back</button>
                    </div>
                </div>
            </div>
            <div className="main-content mc-full-height">
                <div className="container-fluid h-100">
                    <div className="d-flex h-100">
                        <div className="plist-card w300">
                            <div className="plist-card-head">Programs</div>
                            <div className="plist-card-body">
                                <ul className="items-ul">
                                    {d.programs.map(r=>(
                                    <li key={r.id} className={(r.id===d.sdata.program_id?'active':'')} onClick={()=>fn.selectProgram(r)}>
                                        {r.title}
                                        <div className="pt3 note-text">{r[cf]+' '+pageNames}</div>
                                    </li>))}
                                </ul>
                            </div>
                        </div>

                        <div className="plist-card w100-305">
                            <div className="plist-card-head">{pageNames} <span className="text-secondary">/ {d.selPrgName}</span></div>
                            <div className="plist-card-body">
                                <If cond={!d.sdata.program_id}><div className="p30 text-center text-secondary">Select any program to see {fn.getPageName()}</div></If>

                                <If cond={d.sdata.program_id}>
                                    <div className="p8">
                                        <If cond={d.list.result.length}>
                                            <div className="d-flex">
                                                <div className="text-secondary bold600 mb5">
                                                    Showing {d.list.page.start+1} - {d.list.page.start+d.list.page.total} of {d.list.page.total_records} records.
                                                </div>
                                            </div>
                                        </If>

                                        <div className="d-flex tbl-search-head">
                                            <div className="form-inline">
                                                <form id="sfrm" onSubmit={fn.lists} autoComplete="off" spellCheck="false">
                                                    <div className="input-group">
                                                        <input type="search" className="form-control" name="k" placeholder="Search" />
                                                        <div className="input-group-append">
                                                            <button className="btn btn-light-blue" type="submit"><i className="fa fa-search"></i></button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>

                                            <div className="ml-auto mt-auto mb-auto">
                                                <button className="btn btn-primary" onClick={()=>fn.openForm()}><i className="fa fa-plus"></i> Add</button>
                                            </div>
                                        </div>

                                        <If cond={d.list.result.length}>
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-sm table-striped table-hover m-0">
                                                    <thead className="thead-light text-uppercase table-text-vmid font-sm">
                                                        <tr>
                                                            <th className="w20">SN</th>
                                                            <th>{d.sdata.type==='C'?'Course':'Assignment'} Name</th>
                                                            <th className="w80 nowrap">Disp. Odr</th>
                                                            <th className="w80">Status</th>
                                                            <th className="w80"></th>
                                                            <th className="w70"></th>
                                                        </tr>
                                                    </thead>

                                                    <tbody className="table-text-vmid">
                                                        {d.list.result.map((v, i)=>(
                                                            <tr key={i}>
                                                                <td>{d.list.page.start+i+1}.</td>
                                                                <td>{v.title}</td>
                                                                <td>{v.disp_odr}</td>
                                                                <td>
                                                                    <If cond={v.status==='1'}>
                                                                        <div className="badge badge-success">Active</div>
                                                                    </If>
                                                                    <If cond={v.status==='0'}>
                                                                        <div className="badge badge-danger">Inactive</div>
                                                                    </If>
                                                                </td>
                                                                <td className="text-center">
                                                                    <Link to={(d.sdata.type==='C'?'/course':(d.sdata.type==='A'?'/assignment':'/test'))+'-modules/'+v.id} className="btn btn-sm btn-info nowrap">Modules <span className="badge badge-warning">{v.no_of_modules}</span></Link>
                                                                </td>
                                                                <td className="text-center">
                                                                    <div className="btn-group">
                                                                        <button type="button" className="btn btn-sm btn-light-blue gr" onClick={()=>fn.openForm(v)}><i className="fa fa-edit"></i></button>
                                                                        <button type="button" className="btn btn-sm btn-danger gr" onClick={()=>fn.deleterec(v.id)}><i className="fa fa-trash-o"></i></button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="d-flex tbl-foot-bx">
                                                <div className="mr5">
                                                    <Paging fn={fn.lists} pageob={d.list.page} />
                                                </div>
                                                <div>
                                                    <Perpagedd fn={fn.lists} />
                                                </div>
                                            </div>
                                        </If>
                                        <If cond={!d.list.result.length}>
                                            <div className="no-rec">No record found</div>
                                        </If>
                                    </div>
                                </If>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {Form(d, fn)}
        </>
    )
}

function Form(d, fn){
    return(
        <div className="modal" id="formModal" data-backdrop="static">
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">{d.data.id?'Edit':'Add'} {fn.getPageName()}</div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <form id="frm" autoComplete="off" spellCheck="false">
                            <input type="hidden" name="id" defaultValue={d.data.id} />
                            <input type="hidden" name="type" defaultValue={d.data.type} />
                            <input type="hidden" name="program_id" defaultValue={d.data.program_id} />
                            <div className="row mingap">
                                <div className="form-group col-md-12">
                                    {/* <label>Program</label>
                                    <div className="form-control bold600">{d.selPrgName}</div>
                                    {<select className="form-control" name="program_id" value={d.data.program_id || ''} onChange={fn.handleChange}>
                                        <option value="">Select</option>
                                        {d.programs.map((row)=><option key={row.id} value={row.id}>{row.title}</option>)}
                                    </select>} */}
                                    <div className="form-control bg-light">
                                        <span className="bread-act"> {d.selPrgName}</span>
                                    </div>
                                </div>

                                <div className="form-group col-md-12">
                                    <label className="req">{d.type} Name</label>
                                    <input type="text" className="form-control" name="title" value={d.data.title || ''} onChange={fn.handleChange} />
                                </div>
                                <div className="form-group col-md-12">
                                    <label className="req">Description</label>
                                    <textarea className="form-control" name="description" value={d.data.description || ''} onChange={fn.handleChange}></textarea>
                                </div>
                                
                                <div className="form-group col-md-6">
                                    <label className="">Display Order</label>
                                    <input type="text" className="form-control" name="disp_odr" value={d.data.disp_odr || ''} onChange={fn.handleChange} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label className="req">Status</label>
                                    <select className="form-control" name="status" value={d.data.status || ''} onChange={fn.handleChange}>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={fn.save}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}