import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import util from "../../util/util";
import { If, Paging, Perpagedd } from "../../util/controls";
let $ = window.$;

let cRob = {};

export default function CourseModules(props) {
    const [d, setData] = useState({
        list: { result: [], page: {} },
        programs: [],
        courses: [],
        selPrgName: '',
        selCourseName: '',
        sdata: { p: 1, ps: 25, type: props.type, program_id: 0, course_id: 0 },
        data: {},
        qsdata:{},
        sections: [],
        selModule:{},
    });
    const fn = {};
    fn.render = () => { setData({ ...d }) }

    fn.handleChange = (e, dobj) => {
        if (!dobj) {
            dobj = d.data;
        }
        dobj[e.target.name] = e.target.value;
        setData({ ...d });
    }

    fn.sdata = (p, ps) => {
        d.sdata.p = p ? p : 1;
        d.sdata.ps = ps ? ps : d.sdata.ps;
        return d.sdata;
    }
    fn.lists = (e, p, ps) => {
        if (e) e.preventDefault();
        util.ajx("master/course_modules", fn.sdata(p, ps)).then(res => {
            if (res.code === 200) {
                setData({ ...d, list: res.result });
            }
        })
    }

    fn.selectProgram = (rob) => {
        d.sdata.program_id = rob.id;
        d.selPrgName = rob.title;
        d.sdata.course_id = 0;
        d.selCourseName = '';
        fn.render();
    }
    fn.selectCourse=(rob)=>{
        cRob=rob;
        d.sdata.course_id=rob.id;
        d.selCourseName=rob.title;
        fn.lists();
    }

    fn.openForm=(dtl)=>{
        setData({ ...d, data: dtl?{ ...dtl }:{course_id:d.sdata.course_id, type:d.sdata.type}});
        window.showModal($("#formModal"));
        $('#formModal input[type="file"]').val('');
    }

    fn.save = () => {
        let fd = new FormData($("#frm")[0]);
        util.ajx("master/save_course_module", fd).then(res => {
            if (res.code === 200) {
                if (!d.data.id) {
                    cRob['countm'] += 1;
                }
                fn.lists(0, d.data.id ? d.sdata.p : 1);
                window.hideModal($("#formModal"));
            }
        })
    }

    fn.openQuesSectionFormModal=(dtl)=>{
        cRob=dtl;
        d.selModule={id:dtl.id, title:dtl.title};
        util.ajx("master/course_module_sections/" + dtl.id).then(res =>{
            if(res.code==200){
                d.sections=res.result;
                if(!d.sections.length){
                    d.sections=[{}];
                }
                fn.render();
                window.showModal($("#quesSectionFormModal"));
            }
        })
    }

    fn.save_course_module_sections = () =>{
        let fd = new FormData($("#sectionFrm")[0]);
        util.ajx("master/save_course_module_sections", fd).then(res => {
            if (res.code === 200) {
                cRob.no_of_sections=res.no_of_sections;
                fn.render();
                window.hideModal($("#quesSectionFormModal"));
            }
        })
    }

    fn.deleterec = (id) => {
        let callback = () => {
            util.ajx("master/delete_course_module", { id: id }).then(res => {
                if (res.code === 200) {
                    cRob['countm'] -= 1;
                    fn.lists();
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);
    }

    fn.progCcountFeild = () => {
        return props.type === 'C' ? 'countc' : (props.type == 'A' ? 'counta' : 'countt');
    }
    fn.getPcountLabel = (flg) => {
        return (props.type === 'C' ? 'Course' : (props.type == 'A' ? 'Assignment' : 'Mock Test')) + (flg ? 's' : '');
    }

    fn.getPageName = () => {
        return props.type == "C" ? "Course Modules" : (props.type == "A" ? "Assignment Modules" : "Mock Tests Modules");
    }

    fn.init = () => {
        util.ajx("master/init_course_module", d.sdata).then(res => {
            if (res.code === 200) {
                res.courses.forEach((v) => {
                    if (d.sdata.course_id === v.id) {
                        d.selCourseName = v.title;
                        d.sdata.program_id = v.program_id;
                        cRob = v;
                    }
                })
                res.programs.forEach((v) => {
                    if (d.sdata.program_id === v.id) {
                        d.selPrgName = v.title;
                    }
                })

                setData({ ...d, list: res.result, programs: res.programs, courses: res.courses });
            }
        });
    }

    useEffect(() => {
        let course_id = props.computedMatch.params.course_id || '';
        d.sdata = { ...d.sdata, course_id: course_id, type: props.type }
        fn.init();
    }, [props.type]);

    return List(d, fn);
}

function List(d, fn) {
    let cf = fn.progCcountFeild();
    return (<>
        <div className="page-head sm">
            <div className="container-fluid d-flex">
                <h5 className="mt-auto mb-auto pr15">{fn.getPageName()}</h5>
                <div className="mt-auto mb-auto font-xs text-secondary">
                    {d.selPrgName && <span className="bsep">{d.selPrgName}</span>}
                    <span className="bread-act"> {d.selCourseName}</span>
                </div>
                <div className="ml-auto mt-auto mb-auto">
                    <button className="btn btn-sm btn-outline-secondary bdr-radius-0" onClick={() => window.history.back()}><i className="fa fa-chevron-circle-left"></i> Back</button>
                </div>
            </div>
        </div>

        <div className="main-content mc-full-height">
            <div className="container-fluid h-100">
                <div className="d-flex h-100">
                    <div className="plist-card w250">
                        <div className="plist-card-head">Programs</div>
                        <div className="plist-card-body">
                            <ul className="items-ul">
                                {d.programs.map(r => (
                                    <li key={r.id} className={r.id === d.sdata.program_id ? 'active' : ''} onClick={() => fn.selectProgram(r)}>
                                        {r.title}
                                        <div className="pt3 note-text">{r[cf] + ' ' + fn.getPcountLabel(1)}</div>
                                    </li>))}
                            </ul>
                        </div>
                    </div>
                    <div className="plist-card w250">
                        <div className="plist-card-head">{fn.getPcountLabel(1)}</div>
                        <div className="plist-card-body">
                            <ul className="items-ul">
                                {d.courses.map(r => (
                                    <If key={r.id} cond={r.program_id === d.sdata.program_id}>
                                        <li className={r.id === d.sdata.course_id ? 'active' : ''} onClick={() => fn.selectCourse(r)}>
                                            {r.title}
                                            <div className="pt3 note-text">{r['countm']} Modules</div>
                                        </li>
                                    </If>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="plist-card w100-510">
                        <div className="plist-card-head">{fn.getPageName()} <span className="text-secondary">/ {d.selCourseName}</span></div>
                        <div className="plist-card-body">
                            <If cond={!d.sdata.course_id}><div className="p30 text-center text-secondary">Select program / {fn.getPcountLabel()} to see modules</div></If>

                            <If cond={d.sdata.course_id}>
                                <div className="p8">
                                    <If cond={d.list.result.length}>
                                        <div className="d-flex">
                                            <div className="text-secondary bold600 mb5">
                                                Showing {d.list.page.start + 1} - {d.list.page.start + d.list.page.total} of {d.list.page.total_records} records.
                                                </div>
                                        </div>
                                    </If>

                                    <div className="d-flex tbl-search-head">
                                        <div className="form-inline">
                                            <form id="sfrm" onSubmit={fn.lists} autoComplete="off" spellCheck="false">
                                                <div className="input-group">
                                                    <input type="search" className="form-control" name="k" placeholder="Search" value={d.sdata.k || ''} onChange={e => fn.handleChange(e, d.sdata)} />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-light-blue" type="submit"><i className="fa fa-search"></i></button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        <div className="ml-auto mt-auto mb-auto">
                                            <button className="btn btn-primary" onClick={() => fn.openForm()}><i className="fa fa-plus"></i> Add</button>
                                        </div>
                                    </div>

                                    <If cond={d.list.result.length}>
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-sm table-striped table-hover m-0">
                                                <thead className="thead-light text-uppercase table-text-vmid font-sm">
                                                    <tr>
                                                        <th className="w20">SN</th>
                                                        <th className="w100">Image</th>
                                                        <th>Module Name</th>
                                                        <th className="w80">Status</th>
                                                        <th className="w80"></th>
                                                        <th className="w70"></th>
                                                    </tr>
                                                </thead>

                                                <tbody className="table-text-vtop">
                                                    {d.list.result.map((v, i) => (
                                                        <tr key={i}>
                                                            <td>{d.list.page.start + i + 1}.</td>
                                                            <td><img src={v.image_url} alt="Image" className="mw-100"></img></td>
                                                            <td>
                                                                {v.title}
                                                                <div className="pt3 note-text">{v.short_desc}</div>
                                                            </td>
                                                            
                                                            <td>
                                                                <If cond={v.status === '1'}>
                                                                    <div className="badge badge-success">Active</div>
                                                                </If>
                                                                <If cond={v.status === '0'}>
                                                                    <div className="badge badge-danger">Inactive</div>
                                                                </If>
                                                            </td>
                                                            <td className="text-center nowrap">
                                                                {d.sdata.type === 'C' && <Link className="btn btn-sm btn-info nowrap" to={'/sessions/' + v.id}>Sessions <span className="badge badge-warning">{v.no_of_sessions}</span></Link>}
                                                                {d.sdata.type === 'A' && <Link className="btn btn-sm btn-info nowrap" to={'/questions/' + v.id}>Questions <span className="badge badge-warning">{v.no_of_questions}</span></Link>}
                                                                {d.sdata.type === 'T' && <Link className="btn btn-sm btn-info nowrap" to={'/test-questions/' + v.id}>Questions <span className="badge badge-warning">{v.no_of_questions}</span></Link>}

                                                                {d.sdata.type === 'T' && <button type="button" className="btn btn-sm btn-light-blue gr ml5" onClick={() => fn.openQuesSectionFormModal(v)}>Sections <span className="badge badge-warning">{v.no_of_sections}</span></button>}
                                                            </td>
                                                            <td className="text-center">
                                                                <div className="btn-group">
                                                                    <button type="button" className="btn btn-sm btn-light-blue gr" onClick={() => fn.openForm(v)}><i className="fa fa-edit"></i></button>
                                                                    <button type="button" className="btn btn-sm btn-danger gr" onClick={() => fn.deleterec(v.id)}><i className="fa fa-trash-o"></i></button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="d-flex tbl-foot-bx">
                                            <div className="mr5">
                                                <Paging fn={fn.lists} pageob={d.list.page} />
                                            </div>
                                            <div>
                                                <Perpagedd fn={fn.lists} />
                                            </div>
                                        </div>
                                    </If>
                                    <If cond={!d.list.result.length}>
                                        <div className="no-rec">No record found</div>
                                    </If>
                                </div>
                            </If>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {Form(d, fn)}
        {quesSectionForm(d, fn)}
    </>
    )
}

function Form(d, fn) {
    return (
        <div className="modal" id="formModal" data-backdrop="static">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">{d.data.id ? 'Edit' : 'Add'} {fn.getPageName()}</div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <form id="frm" autoComplete="off" spellCheck="false">
                            <input type="hidden" name="id" defaultValue={d.data.id} />
                            <input type="hidden" name="course_id" defaultValue={d.data.course_id} />

                            <div className="mb10 form-control bg-light">
                                {d.selPrgName && <span className="bsep">{d.selPrgName}</span>}
                                {d.selCourseName && <span className="bread-act"> {d.selCourseName}</span>}
                            </div>

                            <div className="row mingap">
                                <div className="form-group col-md-12">
                                    <label className="req">Module Name</label>
                                    <input type="text" className="form-control" name="title" value={d.data.title || ''} onChange={fn.handleChange} />
                                </div>
                                <div className="form-group col-md-12">
                                    <label className="req">Description</label>
                                    <textarea className="form-control" name="description" value={d.data.description || ''} onChange={fn.handleChange}></textarea>
                                </div>
                                <div className="form-group col-md-12">
                                    <label className="req">Image</label>
                                    <input type="file" className="form-control" name="image" />
                                    {d.data.image_url && <div className="pt5"><img src={d.data.image_url} alt="Image" className="w100" /></div>}
                                </div>

                                <div className="form-group col-md-6">
                                    <label className="req">Duration (In Minutes)</label>
                                    <input type="number" className="form-control" name="duration" value={d.data.duration || ''} onChange={fn.handleChange} />
                                </div>

                                <div className="form-group col-md-6">
                                    <label className="req">Status</label>
                                    <select className="form-control" name="status" value={d.data.status || ''} onChange={fn.handleChange}>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={fn.save}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}


function quesSectionForm(d, fn) {
    return (
        <div className="modal" id="quesSectionFormModal" data-backdrop="static">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">Module Sections</div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb10 form-control bg-light">
                            <span className="bread-act"> {d.selModule.title}</span>
                        </div>

                        <form id="sectionFrm" autoComplete="off" spellCheck="false">
                            <input type="hidden" name="module_id" defaultValue={d.selModule.id} />

                            <div className="table-responsive mb5">
                                <table className="table table-bordered table-sm table-striped table-hover m-0">
                                    <thead className="thead-light text-uppercase table-text-vmid font-sm">
                                        <tr>
                                            <th className="w20">SN</th>
                                            <th>Section Name</th>
                                            <th className="w100">Duration</th>
                                            <th className="w20"></th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-text-vmid">
                                        {d.sections.map((v, i)=>{
                                            return (
                                            <tr key={i}>
                                                <td>{i+1}.</td>
                                                <td>
                                                    <input type="hidden" name="id[]" value={v.id || ''} />
                                                    <input type="text" className="form-control" name="title[]" value={v.title || ''} onChange={e=>{v.title=e.target.value; fn.render();}} />
                                                </td>
                                                <td>
                                                    <input type="number" className="form-control" name="duration[]" value={v.duration || ''} onChange={e=>{v.duration=e.target.value; fn.render();}} title="In Minutes" />
                                                </td>
                                                
                                                <td className="text-center">
                                                    <div className="btn-group">
                                                        <button type="button" className="btn btn-sm btn-danger gr" disabled={i===0} onClick={()=>{
                                                            d.sections.splice(i, 1);
                                                            fn.render();
                                                        }}><i className="fa fa-trash-o"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )})}
                                    </tbody>
                                </table>
                            </div>
                            <button type="button" className="btn btn-secondary" onClick={() => {
                                d.sections.push([]);
                                fn.render();
                            }}><i className="fa fa-plus"></i> Add MORE</button>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={fn.save_course_module_sections}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}