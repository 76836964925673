import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import Routes from "./util/routes";

function App() {// /learnnata/front/build/
    return (
      <BrowserRouter basename={"/"}>
          <Routes></Routes>
      </BrowserRouter>
    );
}

export default App;