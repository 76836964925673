import React, {useState, useEffect} from 'react';
import {If, Htmlvideo, VimeoVideo} from "../../util/controls";
import util from "../../util/util";
let $=window.$;

export default function HomePageSettings() {
    const [d, setData]=useState({
        data:{type:'Sliders'},
        list:[],
    });

    const handleChange=(e)=>{
        d.data[e.target.name]=e.target.value;
        setData({...d});
    }

    const setType=(type)=>{
        d.data.type=type;
        lists();
    }

    const GetLabel=()=>{
        let label='';
        switch(d.data.type){
            case 'Sliders':         label='Slider';             break;
            case 'Objectives':      label='Objective';          break;
            case 'TourVideo':       label='Tour Video';         break;
            case 'Expertise':       label='Expertise';          break;
            case 'UpcomingEvents':  label='Upcoming Events';    break;
            case 'Testimonials':    label='Testimonial';        break;
            case 'Brands':          label='Brand';              break;
            case 'BottomInfo':      label='Bottom Info';        break;
            default:                                            break;
        }
        return label;
    }
    
    const openForm=(dtl)=>{
        d.data=dtl?{...dtl}:{type:d.data.type};
        setData({...d});
        window.showModal($("#formModal"), true);
        $('#formModal input[type=file]').val('');
    }

    const pauseVideo=()=>{
        let video=$(".vplayer1")[0];
        if(video && typeof video !== "undefined"){
            video.pause();
        }
    }

    const lists=(e)=>{
        if(e)e.preventDefault();
        util.ajx("master/home_page_settings", {type:d.data.type, k:$("#search_k").val()}).then(res=>{
            if(res.code===200){
                d.list=res.result;
                setData({...d});
            }
        })
    }

    const save=()=>{
        let fd=new FormData($("#frm")[0]);
        util.ajx("master/save_home_page_settings", fd).then(res=>{
            if(res.code===200){
                pauseVideo();
                window.hideModal($("#formModal"));
                lists();
            }
        })
    }

    const deleterec=(id)=>{
        let callback=()=>{
            util.ajx("master/delete_home_page_settings", {id:id}).then(res=>{
                if(res.code===200){
                    lists();
                }
            })
        }
        window.openConfirmBootbox("Confirmation", "Are you sure to delete this record?", callback);
    }

    const init=()=>{
        lists();
    }
    useEffect(()=>{init();}, []);

    return <>
        <div className="page-head">
            <div className="container-fluid d-flex">
                <h5 className="mt-auto mb-auto">Home Page Settings</h5>
                <div className="ml-auto"></div>
            </div>
        </div>

        <div className="main-content">
            <div className="container-fluid">
                <div className="tabbable-custom">
                    <ul className="nav nav-tabs uc">
                        <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#tab1" onClick={()=>setType('Sliders')}>Sliders</a></li>
                        <li className="nav-item"><a className="nav-link" id="tb2" data-toggle="tab" href="#tab1" onClick={()=>setType('Objectives')}>Objectives</a></li>
                        <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#tab1" onClick={()=>setType('TourVideo')}>Tour Video</a></li>
                        <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#tab1" onClick={()=>setType('Expertise')}>Expertise</a></li>
                        <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#tab1" onClick={()=>setType('UpcomingEvents')}>Upcoming Events</a></li>
                        <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#tab1" onClick={()=>setType('Testimonials')}>Testimonials</a></li>
                        <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#tab1" onClick={()=>setType('Brands')}>Brands</a></li>
                        <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#tab1" onClick={()=>setType('BottomInfo')}>Bottom Info</a></li>
                    </ul>

                    <div className="tab-content">
                        <div id="tab1" className="tab-pane fade show active">
                            <div className="d-flex tbl-search-head">
                                <div className="form-inline">
                                    <form id="sfrm" onSubmit={lists} autoComplete="off" spellCheck="false">
                                        <div className="input-group">
                                            <input type="search" className="form-control" name="k" id="search_k" placeholder="Search" />
                                            <div className="input-group-append">
                                                <button className="btn btn-light-blue" type="submit"><i className="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div className="ml-auto mt-auto mb-auto">
                                    <button className="btn btn-primary" onClick={()=>openForm()}><i className="fa fa-plus"></i> Add</button>
                                </div>
                            </div>

                            <If cond={d.list.length}>
                                <div className="table-responsive">
                                    <table className="table table-bordered table-sm table-striped table-hover m-0">
                                        <thead className="thead-light text-uppercase table-text-vmid font-sm">
                                            <tr>
                                                <th className="w20">SN</th>
                                                <If cond={d.data.type!='UpcomingEvents'}>
                                                    <th className="w100">Image</th>
                                                </If>
                                                <th>Title / Subtitle / Description</th>
                                                <th className="w100">Status</th>
                                                <th className="w70"></th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-text-vtop">
                                            {d.list.map((v, i)=>(
                                                <tr key={i}>
                                                    <td>{i+1}.</td>
                                                    <If cond={d.data.type!='UpcomingEvents'}>
                                                        <td><img className="mw-100" src={v.image_url} /></td>
                                                    </If>
                                                    
                                                    <td>
                                                        <div className="bold600">{v.title}</div>
                                                        {v.subtitle && <div className="pt3 note-text bold600">{v.subtitle}</div>}
                                                        {v.description && <div className="pt3 note-text">{v.description}</div>}
                                                        {v.release_date && <div className="pt3 note-text bold">{v.release_date_show}</div>}
                                                    </td>
                                                    
                                                    <td>
                                                        <If cond={v.status==='1'}>
                                                            <div className="badge badge-success">Active</div>
                                                        </If>
                                                        <If cond={v.status==='0'}>
                                                            <div className="badge badge-danger">Inactive</div>
                                                        </If>    
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="btn-group">
                                                            <button type="button" className="btn btn-sm btn-light-blue gr" onClick={()=>openForm(v)}><i className="fa fa-edit"></i></button>
                                                            <button type="button" className="btn btn-sm btn-danger gr" onClick={()=>deleterec(v.id)}><i className="fa fa-trash-o"></i></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </If>
                            <If cond={!d.list.length}>
                                <div className="no-rec">No record found</div>
                            </If>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        {/* Form */}
        <div className="modal modal-scroll" id="formModal" data-backdrop="static">
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">{d.data.id?'Edit':'Add'} <GetLabel /></div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={pauseVideo}><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <form id="frm" autoComplete="off" spellCheck="false">
                            <input type="hidden" name="id" defaultValue={d.data.id} />
                            <input type="hidden" name="type" defaultValue={d.data.type} />

                            <div className="row mingap">
                                <div className="form-group col-md-12">
                                    <label className="req">Title</label>
                                    <input type="text" className="form-control" name="title" value={d.data.title || ''} onChange={handleChange} />
                                </div>

                                <div className="form-group col-md-12">
                                    <label className="">Subtitle</label>
                                    <input type="text" className="form-control" name="subtitle" value={d.data.subtitle || ''} onChange={handleChange} />
                                </div>

                                <If cond={d.data.type == 'Expertise' || d.data.type == 'Testimonials' || d.data.type == 'BottomInfo'}>
                                    <div className="form-group col-md-12">
                                        <label className="req">Description</label>
                                        <textarea rows="te3xt" className="form-control" name="description" value={d.data.description || ''} onChange={handleChange}></textarea>
                                    </div>
                                </If>

                                <If cond={d.data.type != 'UpcomingEvents'}>
                                    <div className="form-group col-md-12">
                                        <label className="req">Image</label>
                                        <input type="file"  className="form-control" name="image" ></input>
                                        <If cond={d.data.image_url}>
                                            <img className="w100 mt-2" src={d.data.image_url} alt="Image"></img>
                                        </If>
                                    </div>
                                </If>

                                <If cond={d.data.type == 'TourVideo'}>
                                    <div className="form-group col-md-12">
                                        <div className="mb10">
                                            <label className="req">Video (Vimeo Url)</label>
                                            <input type="text" className="form-control" name="vimeo_video_url" value={d.data.vimeo_video_url || ''} onChange={handleChange} />
                                            <If cond={d.data.vimeo_id}>
                                                <div className="mt5">
                                                    <VimeoVideo videoid={d.data.vimeo_id} />
                                                </div>
                                            </If>
                                        </div>

                                        <label className="">Video (File Upload)</label>
                                        <input type="file" className="form-control" name="video" />
                                        {d.data.video_url &&
                                        <div className="pt5">
                                            <div style={{fontSize:0}}>
                                                <Htmlvideo className="vplayer1 frmvp" src={d.data.video_url} />
                                            </div>
                                        </div>}
                                    </div>
                                </If>

                                <If cond={d.data.type == 'UpcomingEvents'}>
                                    <div className="form-group col-md-12">
                                        <label className="req">Release Date</label>
                                        <input type="date" className="form-control" name="release_date" value={d.data.release_date|| ''} onChange={handleChange}></input>
                                    </div>
                                </If>

                                <div className="form-group col-md-12"> 
                                    <label className="req">Status</label>
                                    <select className="form-control" name="status" value={d.data.status || ''} onChange={handleChange}>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={pauseVideo}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={save}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    </>;
}