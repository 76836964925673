import React, {useState, useEffect} from 'react';
import util from "../../util/util";
import {If} from "../../util/controls";
let $=window.$;

export default function Login(props) {
    const fn={};
    const [d, setData]=useState({
        showloader:false,
    });
    const reRender=()=>{
        setData({...d});
    }

    fn.login=(e)=>{
        if(e)e.preventDefault();
        d.showloader=true;
        reRender();

        let fd=new FormData($("#frm")[0]);
        util.ajx("auth/login/", fd, 0,1).then(res=>{
            d.showloader=false;
            reRender();
            if(res.code===200){
                util.setLoginInfoLocalStorage(res.authtoken, res.user.name, res.user.role_name);
                props.history.push('/dashboard');
                //window.location.href='dashboard';
            }
        })
    }
    
    const init=()=>{
    }
    useEffect(()=>{init();}, []);

    return View(d, fn);
}

function View(d, fn){
    return(
        <div className="h-100 overflow-auto login-bg">
            <div className="login-bx ml-auto mr-auto mt100 shadow">
                <div className="login-logo">
                    {/* <h3 className="m-0 text-info">Login to Learn NATA</h3> */}
                    <img src="assets/img/logo.png" alt="" />
                </div>
                <form id="frm" onSubmit={fn.login} autoComplete="off" spellCheck="false">
                    <div className="form-group">
                        <div className="input-group">
                            <div className="input-group-prepend"><span className="input-group-text bold">@</span></div>
                            <input type="text" className="form-control" name="username" defaultValue="" placeholder="Username" />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <div className="input-group-prepend"><span className="input-group-text"><i className="fa fa-cog"></i></span></div>
                            <input type="password" className="form-control" name="password" defaultValue="" placeholder="Password" />
                        </div>
                    </div>
                    
                    <If cond={!d.showloader}>
                        <button type="submit" className="btn btn-primary btn-block">Login</button>
                    </If>
                    <If cond={d.showloader}>
                        <div className="text-center">
                            <div className="spinner-grow text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <div className="spinner-grow text-secondary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <div className="spinner-grow text-success" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <div className="spinner-grow text-danger" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <div className="spinner-grow text-warning" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <div className="spinner-grow text-info" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <div className="spinner-grow text-light" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <div className="spinner-grow text-dark" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </If>
                </form>
            </div>
        </div>
    )
}