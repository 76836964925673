import React, {useState, useEffect} from 'react';
import ReactDOMServer from 'react-dom/server';
import util from "../../util/util";
import {If, Paging, Perpagedd, RawHTML} from "../../util/controls";
let $=window.$;

function setPopover(){
    $('[data-toggle="popover"]').popover('dispose')
    $('[data-toggle="popover"]').popover({
        trigger: 'focus',
        sanitize: false,
    })
}

let ref={};
export default function Students() {
    const fn={};
    const [d, setData]=useState({
        list:{result:[], page:{}},
        sdata:{p:1, ps:25},
        data:{}
    });

    fn.render=()=>{
        setData({...d});
    }

    fn.handleChange=(e)=>{
        d.data[e.target.name]=e.target.value;
        fn.render();
    }

    fn.sdata=(p, ps)=>{
        d.sdata.p=p?p:1;
        d.sdata.ps=ps?ps:d.sdata.ps;
        return $("#sfrm").serialize()+'&p='+d.sdata.p+'&ps='+d.sdata.ps;
    }
    fn.lists=(e, p, ps)=>{
        if(e)e.preventDefault();
        util.ajx("student/students", fn.sdata(p, ps)).then(res=>{
            if(res.code===200){
                d.list=res.result;
                fn.render();
            }
        })
    }

    fn.init=()=>{
        fn.lists();
    }
    useEffect(()=>{fn.init();}, []);

    return (
        <>
            <View d={d} fn={fn} />
            <ProfileDetail refOb={ref} />
        </>
    )
}

function View(props){
    let d=props.d, fn=props.fn;
    return(
        <>
            <div className="page-head">
                <div className="container-fluid d-flex">
                    <h5 className="mt-auto mb-auto">Students</h5>
                </div>
            </div>

            <div className="main-content">
                <div className="container-fluid">
                    <If cond={d.list.result.length}>
                        <div className="d-flex">
                            <div className="text-secondary bold600 mb5">
                                Showing {d.list.page.start+1} - {d.list.page.start+d.list.page.total} of {d.list.page.total_records} records.
                            </div>
                        </div>
                    </If>

                    <div className="d-flex tbl-search-head">
                        <div className="form-inline">
                            <form id="sfrm" onSubmit={fn.lists} autoComplete="off" spellCheck="false">
                                <input type="search" className="form-control w300" name="k" placeholder="Search name/email" />
                                <select className="form-control ml5" name="gender">
                                    <option value="">Gender (All)</option>
                                    <option>Male</option>
                                    <option>Female</option>
                                </select>
                                <select className="form-control ml5" name="subscribed">
                                    <option value="">Subscription (All)</option>
                                    <option value="Y">Subscribed</option>
                                    <option value="N">Not Subscribed</option>
                                </select>
                                <select className="form-control ml5" name="status">
                                    <option value="">Status (All)</option>
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>
                                </select>
                                <button className="btn btn-light-blue ml5" type="submit"><i className="fa fa-search"></i> Search</button>
                            </form>
                        </div>

                        <div className="ml-auto mt-auto mb-auto">
                        </div>
                    </div>

                    <div>
                        <If cond={d.list.result.length}>
                            <div className="table-responsive">
                                <table className="table table-bordered table-sm table-striped table-hover m-0">
                                    <thead className="thead-light text-uppercase table-text-vmid font-sm">
                                        <tr>
                                            <th className="w20">SN</th>
                                            <th>Name</th>
                                            <th className="w250">Email</th>
                                            <th className="w100">Gender</th>
                                            <th className="w80">Subscribed</th>
                                            <th className="w100">Reg. Date</th>
                                            <th className="w90">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-text-vmid">
                                        {d.list.result.map((v, i)=>(
                                            <tr key={i}>
                                                <td>{d.list.page.start+i+1}.</td>
                                                <td>
                                                    <button type="button" className="btn btn-sm btn-primary float-right" onClick={ref.viewDetail(v.id)}>View Profile</button>
                                                    {v.fname} {v.lname}
                                                </td>
                                                <td className="breakword1">{v.email}</td>
                                                <td className="nowrap">{v.gender}</td>
                                                <td>
                                                    <If cond={v.subscribed==='Y'}>
                                                        <div className="badge badge-success">YES</div>
                                                    </If>
                                                    <If cond={v.subscribed!=='Y'}>
                                                        <div className="badge badge-danger">NO</div>
                                                    </If>    
                                                </td>
                                                <td>{v.created}</td>
                                                <td>
                                                     <StatusSwitch id={v.id} status={v.status} />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex tbl-foot-bx">
                                <div className="mr5">
                                    <Paging fn={fn.lists} pageob={d.list.page} />
                                </div>
                                <div>
                                    <Perpagedd fn={fn.lists} />
                                </div>
                            </div>
                        </If>
                        <If cond={!d.list.result.length}>
                            <div className="no-rec">No record found</div>
                        </If>
                    </div>
                </div>
            </div>
        </>
    )
}

function StatusSwitch(props){
    let htmlid='c'+props.id;
    const [status, setStatus]=useState(props.status);

    const updateStatus=()=>{
        let newStatus=status==='1'?'0':'1';
        util.ajx("student/updateStatus", {id:props.id, status:newStatus}).then(res=>{
            if(res.code===200){
                setStatus(newStatus);
            }
        })
    }

    useEffect(()=>{setStatus(props.status);}, [props.id]);

    return(
        <div className="status-switch">
            <div className="custom-control custom-switch">
                <input type="checkbox" id={htmlid} className="custom-control-input" checked={status==='1'} onChange={updateStatus} />
                <label className="custom-control-label" htmlFor={htmlid}>
                    <If cond={status==='1'}><span>Active</span></If>
                    <If cond={status==='0'}><span className="text-danger">Inactive</span></If>
                </label>
            </div>
        </div>
    )
}

function ProfileDetail(props){
    let dtlOb={
        basic:{},
        programs_progress:[],
        course_progress:[],
        assignment_progress:[],
        test_progress:[],
        quesType:''
    };
    const [dtl, setDtl]=useState({...dtlOb});
    const viewDetail=(id)=>()=>{
        util.ajx("student/profile_detail", {id}).then(res=>{
            if(res.code===200){
                setDtl(res.dtl);
                window.showModal($("#stuDtlModal"), true, true);
                $('[href="#tab1"], [href="#pills0"]').click();

                setTimeout(()=>{
                    setPopover();
                }, 0);
            }
        })
    }

    const clearData=()=>{
        setDtl(dtlOb);
    }

    const setQuesType=(type)=>()=>{
        setDtl({...dtl, quesType:type});
    }

    /** Reference to parent */
    props.refOb.viewDetail=viewDetail;

    return(
        <div className="modal modal-scroll" id="stuDtlModal" data-backdrop="static">
            <div className="modal-dialog modal-full" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title uc">Student Detail</div>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={clearData}><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div className="modal-body">
                        <div className="tabbable-custom">
                            <ul className="nav nav-tabs uc">
                                <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#tab1">Profile Detail</a></li>
                                <li className="nav-item"><a className="nav-link" id="tb2" data-toggle="tab" href="#tab2">Progress</a></li>
                                <li className="nav-item"><a className="nav-link" id="tb3" data-toggle="tab" href="#tab3" onClick={setQuesType('A')}>Assignment Questions</a></li>
                                <li className="nav-item"><a className="nav-link" id="tb4" data-toggle="tab" href="#tab3" onClick={setQuesType('T')}>Test Questions</a></li>
                                <li className="nav-item"><a className="nav-link" id="tb5" data-toggle="tab" href="#tab3" onClick={setQuesType('C')}>Session Questions</a></li>
                            </ul>

                            <div className="tab-content">
                                <div id="tab1" className="tab-pane fade show active">
                                    <BasicDtl data={dtl.basic} />
                                </div>

                                <div id="tab2" className="tab-pane fade show">
                                    <div className="">
                                        <ul className="nav nav-pills mb-3">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="pill" href="#pills0">Overall Progress</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="pill" href="#pills1">Course Progress</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="pill" href="#pills2">Assignment Progress</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="pill" href="#pills3">Test Progress</a>
                                            </li>
                                        </ul>

                                        <div className="tab-content nobdr">
                                            <div className="tab-pane fade show active" id="pills0">
                                                <ProgramProgress data={dtl.programs_progress} />
                                            </div>

                                            <div className="tab-pane fade show" id="pills1">
                                                <CourseProgress data={dtl.course_progress} type='C' />
                                            </div>
                                            <div className="tab-pane fade" id="pills2">
                                                <CourseProgress data={dtl.assignment_progress} type='A' />
                                            </div>
                                            <div className="tab-pane fade" id="pills3">
                                                <CourseProgress data={dtl.test_progress} type='T' />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="tab3" className="tab-pane fade show">
                                    <If cond={dtl.quesType==='A' || dtl.quesType==='T' || dtl.quesType==='C'}>
                                        <QuesAttempted type={dtl.quesType} stu_id={dtl.basic.id} />
                                    </If>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={clearData}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function BasicDtl(props){
    let data=props.data;
    return (
        <div>
            <div className="table-responsive mb10">
                <table className="table table-bordered table-sm m-0">
                    <tbody className="table-text-top">
                        <tr>
                            <td className="w120">
                                <img src={data.image_url} alt="" className="mw-100" />
                            </td>
                            <td>
                                <div className="bold600 mb5">{data.name}</div>
                                <div className="note-text">
                                    <div className="mb3"><div className="inblock w60">Reg. Date</div>: <strong>{data.created}</strong></div>
                                    <div className="mb3"><div className="inblock w60">Gender</div>: <strong>{data.gender}</strong></div>
                                    <div className="mb3"><div className="inblock w60">Email</div>: <strong>{data.email}</strong></div>
                                    <div className="mb3"><div className="inblock w60">Gender</div>: <strong>{data.gender}</strong></div>
                                    <div className="mb3">
                                        <div className="inblock w60">Subscribed</div>:&nbsp;
                                        <strong>
                                            <If cond={data.subscribed==='Y'}><span className="text-success">YES</span></If>
                                            <If cond={data.subscribed==='N'}><span className="text-danger">NO</span></If>
                                        </strong>
                                    </div>
                                    <div className="mb3">
                                        <div className="inblock w60">Status</div>:&nbsp;
                                        <strong>
                                            <If cond={data.status}><span className="badge badge-success">Active</span></If>
                                            <If cond={!data.status}><span className="badge badge-danger">Inactive</span></If>
                                        </strong>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="mb10">
                <fieldset>
                    <legend>About</legend>
                    <div className="p10">
                        <RawHTML html={data.about || 'Not Given'} />
                    </div>
                </fieldset>
            </div>

            <div className="mb10">
                <fieldset>
                    <legend>Education &amp; Work</legend>
                    <div className="p10">
                        <div className="table-responsive">
                            <table className="table table-bordered table-sm m-0">
                                <tbody className="table-text-vmid">
                                    <tr>
                                        <td className="bg-light w150">Degree</td>
                                        <td>{data.edu_degree || 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td className="bg-light">Institute</td>
                                        <td>{data.edu_institute || 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td className="bg-light">Job Title</td>
                                        <td>{data.work_title || 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td className="bg-light">Company</td>
                                        <td>{data.work_company || 'N/A'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </fieldset>
            </div>

            <div className="">
                <fieldset>
                    <legend>Social Media Links</legend>
                    <div className="p10">
                        <div className="table-responsive">
                            <table className="table table-bordered table-sm m-0">
                                <tbody className="table-text-vmid">
                                    <tr>
                                        <td className="bg-light w150">Facebook</td>
                                        <td>{data.fb_link || 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td className="bg-light">Twitter</td>
                                        <td>{data.tw_link || 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td className="bg-light">Linked-in</td>
                                        <td>{data.in_link || 'N/A'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    )
}

function ProgramProgress(props){
    let data=props.data;
    return (
        <div>
            <If cond={data.length}>
                <div className="border">
                    <div className="table-responsive">
                        <table className="table table-bordered1 table-sm m-0">
                            <thead className="thead-light text-uppercase table-text-vmid font-sm">
                                <tr>
                                    <th>Program</th>
                                    <th className="text-center w100">Course</th>
                                    <th className="w20"></th>
                                    <th className="text-center w100">Assignment</th>
                                    <th className="w20"></th>
                                    <th className="text-center w100">Test</th>
                                    <th className="w20"></th>
                                    <th className="text-center w100">Overall</th>
                                </tr>
                            </thead>
                            <tbody className="table-text-vmid">
                                {data.map((v, i)=>(
                                    <tr key={i}>
                                        <td>{v.program}</td>
                                        <ProgressBox percent={v.cprogress} />
                                        <td className="text-center bg-light1">+</td>
                                        <ProgressBox percent={v.aprogress} />
                                        <td className="text-center bg-light1">+</td>
                                        <ProgressBox percent={v.tprogress} />
                                        <td className="text-center bg-light1">=</td>
                                        <ProgressBox percent={v.progress} className="bold600" />
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </If>
            <If cond={!data.length}>
                <div className="no-rec">No program is enrolled</div>
            </If>
        </div>
    )
}

function CourseProgress(props){
    const{data, type}=props;
    let title=type==='C'?'Course':(type=='A'?'Assignment':'Test');

    return(
        <div>
            <If cond={data.length}>
                <div className="table-responsive">
                    <table className="table table-bordered table-sm m-0">
                        <thead className="thead-light text-uppercase table-text-vmid font-sm">
                            <tr>
                                <th className="w20">SN</th>
                                <th className="w250">Program</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className="table-text-vmid">
                            {data.map((v, i)=>(
                                <tr key={i}>
                                    <td>{i+1}.</td>
                                    <td className="bold600">{v.group.program}</td>
                                    <td>
                                        <table className="table table-bordered table-striped table-hover table-sm m-0">
                                            <thead className="thead-light1 text-uppercase table-text-vmid font-xs">
                                                <tr>
                                                    <th className="w20">SN</th>
                                                    <th>{title}</th>
                                                    <th className="w60"></th>
                                                    <th className="w100 text-center">Progress</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-text-vmid font-sm">
                                                {v.list.map((r, j)=>(
                                                    <tr key={j}>
                                                        <td>{j+1}.</td>
                                                        <td>{r.course}</td>
                                                        <td className="nowrap">
                                                            <a tabIndex="0" role="button" data-toggle="popover" data-trigger1="focus" data-placement="left" title={r.course} data-html={true} data-content={ModuleProgressHtml(r.modules)} href="/nolink" onClick={e=>e.preventDefault()}>{r.mcount} Modules</a>
                                                        </td>
                                                        <ProgressBox percent={r.progress} />
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </If>
            <If cond={!data.length}>
                <div className="no-rec">No program is enrolled</div>
            </If>
        </div>
    )
}

function ModuleProgressHtml(modules){
    const htmlString=ReactDOMServer.renderToStaticMarkup(
        <div className="sticky-tbl" style={{maxHeight:'250px', width:'500px'}}>
            <div className="pos-sticky-1px"></div>
            <table className="table table-bordered table-striped table-hover table-sm m-0">
                <thead className="thead-light text-uppercase table-text-vmid font-xs">
                    <tr>
                        <th className="w20">SN</th>
                        <th>Module</th>
                        <th className="w100 text-center">Progress</th>
                    </tr>
                </thead>
                <tbody className="table-text-vmid font-sm">
                    {modules.map((v, i)=>(
                        <tr key={i}>
                            <td>{i+1}.</td>
                            <td>{v.title}</td>
                            <td className="text-center">{v.progress}%</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
    return htmlString;
}

function ProgressBox(props){
    let per=props.percent;
    let style={
        position:'absolute', top:0, bottom:0, left:0, right:0, zIndex:1, width:per+'%', background:'#4ccd4373'
    }
    return (
        <td className={"text-center bg-light position-relative "+props.className}>
            <div style={style}></div>
            <div className="position-relative" style={{zIndex:2}}>{per} %</div>
        </td>
    )
}

function QuesAttempted(props) {
    const fn={};
    const [d, setData]=useState({
        list:{result:[], page:{}},
        sdata:{p:1, ps:25},
        programs:[],
        courses:[],
        modules:[],
    });
    fn.render=()=>{
        setData({...d});
    }

    const clearImgWH=()=>{
        setTimeout(()=>{
            $(".ques-img-100 img").removeAttr('width').removeAttr('height');
        }, 0);
    }

    fn.sdata=(p, ps)=>{
        d.sdata.p=p?p:1;
        d.sdata.ps=ps?ps:d.sdata.ps;
        return $("#qsfrm").serialize()+'&p='+d.sdata.p+'&ps='+d.sdata.ps+'&type='+props.type+'&stu_id='+props.stu_id;
    }
    fn.lists=(e, p, ps)=>{
        if(e)e.preventDefault();
        util.ajx("student/ques_attempted", fn.sdata(p, ps)).then(res=>{
            if(res.code===200){
                d.list=res.result;
                fn.render();
                clearImgWH();
            }
        })
    }

    fn.init=()=>{
        util.ajx("student/ques_attempted/INIT", fn.sdata(d.sdata.p, d.sdata.ps)).then(res=>{
            if(res.code===200){
                d.list=res.result;
                d.programs=res.programs;
                d.courses=res.courses;
                d.modules=res.modules;
                fn.render();
                clearImgWH();
            }
        })
    }
    useEffect(()=>{fn.init();}, [props.type]);

    return (
        <div>
            <If cond={d.list.result.length}>
                <div className="d-flex">
                    <div className="text-secondary bold600 mb5">
                        Showing {d.list.page.start+1} - {d.list.page.start+d.list.page.total} of {d.list.page.total_records} records.
                    </div>
                </div>
            </If>
            <div className="d-flex tbl-search-head">
                <div className="form-inline">
                    <form id="qsfrm" onSubmit={fn.lists} autoComplete="off" spellCheck="false">
                        <div className="input-group">
                            <input type="search" className="form-control" name="k" placeholder="Search" />
                            <div className="input-group-append">
                                <button className="btn btn-light-blue" type="submit"><i className="fa fa-search"></i></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <If cond={d.list.result.length}>
                <div className="table-responsive">
                    <table className="table table-bordered table-sm table-striped table-hover1 m-0">
                        <thead className="thead-light text-uppercase table-text-vmid font-sm">
                            <tr>
                                <th className="w20">SN</th>
                                <th>Question</th>
                                <th>Answer Given</th>
                            </tr>
                        </thead>

                        <tbody className="table-text-top">
                            {d.list.result.map((v, i)=>(
                                <tr key={i}>
                                    <td>{d.list.page.start+i+1}.</td>
                                    <td>
                                        <div className="ques-img-100">
                                            <div className="mb3 note-text">
                                                <strong>{v.program} / {v.course} / {v.module} <If cond={v.section || v.session}><span>/ {v.section}{v.session}</span></If></strong>
                                            </div>
                                            <div className="form-control">
                                                <RawHTML html={v.question} />
                                            </div>
                                            <QuesOptions data={v.options} />
                                        </div>
                                    </td>

                                    <td>
                                        <div className="ques-img-100">
                                            <If cond={v.ans}>
                                                <div className="pb5">
                                                    <RawHTML html={v.ans} />
                                                </div>
                                            </If>
                                            <If cond={v.ans_img_url}>
                                                <div className="pb5">
                                                    <a href={v.ans_img_url} target="blank">
                                                        <img src={v.ans_img_url} alt="" />
                                                    </a>
                                                </div>
                                            </If>
                                            <div>
                                                <If cond={v.is_correct_ans==='Y'}>
                                                    <span className="badge badge-success"><i className="fa fa-check-circle"></i> Correct Answer</span>
                                                </If>
                                                <If cond={v.is_correct_ans==='N'}>
                                                    <span className="badge badge-danger"><i className="fa fa-check-circle"></i> Wrong Answer</span>
                                                </If>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="d-flex tbl-foot-bx">
                    <div className="mr5">
                        <Paging fn={fn.lists} pageob={d.list.page} />
                    </div>
                    <div>
                        <Perpagedd fn={fn.lists} />
                    </div>
                </div>
            </If>
            <If cond={!d.list.result.length}>
                <div className="no-rec">No record found</div>
            </If>
        </div>
    )
}

function QuesOptions(props){
    let options=props.data;
    return(
        <div>
            <If cond={options.length}>
                <div className="pt5">
                    <div className="bold600">Your options are:</div>
                    {options.map((v, i)=>(
                        <span key={i} className="badge badge-primary mr5">{i+1}. {v}</span>
                    ))}
                </div>
            </If>
        </div>
    )
}